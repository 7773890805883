// src/App.js
import React, { useState, useEffect } from 'react';
import TripList from './components/TripList';
import CreateTripModal from './components/CreateTripModal';
import EditTripModal from './components/EditTripModal';
import TripDetail from './components/TripDetail/TripDetail';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from './config';
import { IoChevronBack, IoList } from 'react-icons/io5';

import { db, auth } from './firebase';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  getDoc
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { removeCommunityRecommendations } from './suggestionsService';

import LoginModal from './components/UserAuth/LoginModal';
import './styles/common.css'; // your global styles

// Create a stable libraries array outside the component
const GOOGLE_LIBRARIES = ['places'];

function App() {
  const [trips, setTrips] = useState([]);
  const [selectedTripId, setSelectedTripId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editTrip, setEditTrip] = useState(null);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const [mobileShowDetail, setMobileShowDetail] = useState(false);
  const [tripListCollapsed, setTripListCollapsed] = useState(false);

  // Track current user
  const [user, setUser] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(true);

  // Listen for Firebase Auth user changes.
  // Note: Removed `user` from the dependency array so this effect runs only once.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (fbUser) => {
      if (fbUser) {
        // Logged in via Firebase
        setUser({
          uid: fbUser.uid,
          displayName: fbUser.displayName || fbUser.email,
        });
        setShowLoginModal(false);
      } else {
        // Not logged in - show login unless we have a "demo" user.
        if (!user || user.uid !== 'demo') {
          setUser(null);
          setShowLoginModal(true);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  // Adjust layout for mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Load trips only for the current user.
  // (This effect runs whenever the `user` changes.)
  useEffect(() => {
    if (!user) {
      setTrips([]);
      return;
    }
    const q = query(collection(db, 'trips'), where('userId', '==', user.uid));
    const unsub = onSnapshot(q, (snapshot) => {
      const tripData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTrips(tripData);
    });
    return () => unsub();
  }, [user]);

  const selectedTrip = trips.find((t) => t.id === selectedTripId);

  // Create a new trip, tag with userId, and return a valid trip with an id.
  const handleCreateTrip = async (newTrip) => {
    if (!user) return;
    newTrip.userId = user.uid;
    console.log('[App.js] Creating new trip with data:', newTrip);
    const docRef = await addDoc(collection(db, 'trips'), newTrip);
    const tripWithId = { id: docRef.id, ...newTrip };
    console.log('[App.js] Trip created with id:', tripWithId.id);
    setSelectedTripId(tripWithId.id);
    setShowCreateModal(false);
    setSelectedPlaceId(null);
    if (isMobile) {
      setMobileShowDetail(true);
    }
    return tripWithId;
  };

  // Update an existing trip.
  const handleUpdateTrip = async (tripId, updatedTrip) => {
    const tripRef = doc(db, 'trips', tripId);
    await updateDoc(tripRef, updatedTrip);
    setEditTrip(null);
  };

  // Delete a trip (and its sub-collection "places") and clean up community recommendations.
  const handleDeleteTrip = async (tripId) => {
    try {
      // First get the trip details to get the location
      const tripDoc = await getDoc(doc(db, 'trips', tripId));
      if (!tripDoc.exists()) {
        console.error(`Trip ${tripId} not found when trying to delete`);
        return;
      }
      
      const tripData = tripDoc.data();
      const { location } = tripData;
      
      // Delete all places in the trip
      const placesCol = collection(db, 'trips', tripId, 'places');
      const placesSnap = await getDocs(placesCol);
      const batchDeletes = placesSnap.docs.map(d =>
        deleteDoc(doc(db, 'trips', tripId, 'places', d.id))
      );
      await Promise.all(batchDeletes);
      
      // Delete the trip document
      await deleteDoc(doc(db, 'trips', tripId));
      
      // Clean up community recommendations for this user and location
      if (user && location) {
        await removeCommunityRecommendations(user.uid, location);
      }
      
      // Update UI
      if (selectedTripId === tripId) setSelectedTripId(null);
      setSelectedPlaceId(null);
      if (isMobile) {
        setMobileShowDetail(false);
      }
    } catch (error) {
      console.error("Error deleting trip:", error);
    }
  };

  // Select a trip from the list.
  const handleSelectTrip = (tripId) => {
    setSelectedTripId(tripId);
    setSelectedPlaceId(null);
    if (isMobile) {
      setMobileShowDetail(true);
    }
  };

  const handleMenuClick = () => {
    // On mobile detail view, going back to trip list.
    setMobileShowDetail(false);
    setSelectedTripId(null);
    setSelectedPlaceId(null);
  };

  // Called when user logs in or chooses demo.
  const handleUserLogin = (loggedInUser) => {
    setUser(loggedInUser);
    setShowLoginModal(false);
  };

  // Called when user logs out.
  const handleSignOut = () => {
    setUser(null);
  };

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      libraries={GOOGLE_LIBRARIES}
    >
      <div className={`app-container ${isMobile ? 'mobile-view' : ''}`}>
        {/* Show the login modal if no user or if user is null */}
        {showLoginModal && !user && (
          <LoginModal
            onLogin={handleUserLogin}
            onClose={() => setShowLoginModal(false)}
          />
        )}

        {/* MAIN CONTENT */}
        {(!isMobile || !mobileShowDetail) && (
          <div className={`left-panel ${tripListCollapsed ? 'collapsed' : ''}`}>
            {/* Toggle button to collapse/expand the trip list panel */}
            {!tripListCollapsed ? (
              <>
                <div className="collapse-button" onClick={() => setTripListCollapsed(true)}>
                  <IoChevronBack />
                </div>
                <TripList
                  user={user}
                  onSignOut={handleSignOut}
                  onShowLogin={() => setShowLoginModal(true)}
                  trips={trips}
                  selectedTripId={selectedTripId}
                  onSelectTrip={handleSelectTrip}
                  onCreateNewTrip={() => setShowCreateModal(true)}
                  onEditTrip={(trip) => setEditTrip(trip)}
                  onDeleteTrip={handleDeleteTrip}
                />
              </>
            ) : (
              <div className="expand-icon" onClick={() => setTripListCollapsed(false)}>
                <IoList />
              </div>
            )}
          </div>
        )}
        {(!isMobile && selectedTrip) && (
          <div className="right-panel">
            <TripDetail 
              key={selectedTrip.id}
              trip={selectedTrip}
              selectedPlaceId={selectedPlaceId}
              onSelectPlace={(placeId) => setSelectedPlaceId(placeId)}
              onDeselectPlace={() => setSelectedPlaceId(null)}
              isMobile={isMobile}
              onMenuClick={handleMenuClick}
            />
          </div>
        )}
        {isMobile && mobileShowDetail && selectedTrip && (
          <div className="mobile-detail-panel">
            <TripDetail 
              key={selectedTrip.id}
              trip={selectedTrip}
              selectedPlaceId={selectedPlaceId}
              onSelectPlace={(placeId) => setSelectedPlaceId(placeId)}
              onDeselectPlace={() => setSelectedPlaceId(null)}
              isMobile={isMobile}
              onMenuClick={handleMenuClick}
            />
          </div>
        )}

        {/* CREATE / EDIT MODALS */}
        {showCreateModal && (
          <CreateTripModal
            onClose={() => setShowCreateModal(false)}
            onCreate={handleCreateTrip}
          />
        )}
        {editTrip && (
          <EditTripModal
            trip={editTrip}
            onClose={() => setEditTrip(null)}
            onSave={(updatedTrip) => handleUpdateTrip(editTrip.id, updatedTrip)}
          />
        )}
      </div>
    </LoadScript>
  );
}

export default App;
