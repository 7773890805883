// src/components/LoginModal.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import '../../styles/UserAuth/LoginModal.css';

function LoginModal({ onLogin, onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      if (userCredential.user) {
        onLogin({
          uid: userCredential.user.uid,
          displayName: userCredential.user.displayName || userCredential.user.email,
        });
        onClose();
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleContinueAsDemo = () => {
    onLogin({
      uid: 'demo',
      displayName: 'Demo User',
    });
    onClose();
  };

  return (
    <div className="login-modal-backdrop">
      <div className="login-modal-content">
        <h2 className="login-title">{isSignUp ? 'Sign Up' : 'Log In'}</h2>

        {error && <div className="login-error">{error}</div>}

        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="login-email">Email</label>
            <input
              id="login-email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@email.com"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="login-password">Password</label>
            <input
              id="login-password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="********"
              required
            />
          </div>

          <button type="submit" className="login-button">
            {isSignUp ? 'Sign Up' : 'Log In'}
          </button>
        </form>

        <div className="toggle-signup">
          {isSignUp ? (
            <>
              Already have an account?{' '}
              <span onClick={() => setIsSignUp(false)} className="toggle-link">
                Log In
              </span>
            </>
          ) : (
            <>
              Don't have an account?{' '}
              <span onClick={() => setIsSignUp(true)} className="toggle-link">
                Sign Up
              </span>
            </>
          )}
        </div>

        <div className="demo-button-container">
          <button onClick={handleContinueAsDemo} className="demo-button">
            Continue to demo without signing up
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
