// src/components/TripList.js
import React, { useState, useMemo } from 'react';
import { IoPencil, IoTrash, IoPeople } from 'react-icons/io5';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import UserMenu from './UserAuth/UserMenu';
import '../styles/TripList.css';

function formatDate(dateStr) {
  if (!dateStr) return '';
  const parts = dateStr.split('-');
  if (parts.length === 3) {
    const [yyyy, mm, dd] = parts;
    return `${dd}/${mm}/${yyyy}`;
  }
  return dateStr;
}

function formatTripDates(startDate, endDate) {
  const sd = startDate ? formatDate(startDate) : '';
  const ed = endDate ? formatDate(endDate) : '';
  if (sd && ed) {
    return `${sd} - ${ed}`;
  } else if (sd && !ed) {
    return `${sd} -`;
  } else if (!sd && ed) {
    return `- ${ed}`;
  } else {
    return '';
  }
}

function parseDate(dateStr) {
  if (!dateStr) return null;
  const [year, month, day] = dateStr.split('-').map((s) => parseInt(s, 10));
  if (!year || !month || !day) return null;
  return new Date(year, month - 1, day);
}

function isPastTrip(trip) {
  const endDateObj = parseDate(trip.endDate);
  if (!endDateObj) return false;
  const today = new Date();
  return today > endDateObj;
}

function tripSorter(a, b) {
  const aStart = parseDate(a.startDate);
  const bStart = parseDate(b.startDate);

  if (!aStart && !bStart) return 0;
  if (!aStart && bStart) return 1;
  if (aStart && !bStart) return -1;

  if (aStart < bStart) return -1;
  if (aStart > bStart) return 1;

  const aEnd = parseDate(a.endDate);
  const bEnd = parseDate(b.endDate);

  if (!aEnd && !bEnd) return 0;
  if (!aEnd && bEnd) return 1;
  if (aEnd && !bEnd) return -1;

  if (aEnd < bEnd) return -1;
  if (aEnd > bEnd) return 1;
  return 0;
}

function TripList({
  user,
  onSignOut,
  onShowLogin,
  trips,
  selectedTripId,
  onSelectTrip,
  onCreateNewTrip,
  onEditTrip,
  onDeleteTrip,
}) {
  const [collapsed, setCollapsed] = useState({
    current: false,
    past: false,
  });

  const sortedTrips = useMemo(() => {
    return [...trips].sort(tripSorter);
  }, [trips]);

  const [currentTrips, pastTrips] = useMemo(() => {
    const curr = [];
    const p = [];
    sortedTrips.forEach((t) => {
      if (isPastTrip(t)) p.push(t);
      else curr.push(t);
    });
    return [curr, p];
  }, [sortedTrips]);

  const handleCollapseToggle = (section) => {
    setCollapsed((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const renderTripItem = (trip) => {
    const dateStr = formatTripDates(trip.startDate, trip.endDate);
    return (
      <div
        key={trip.id}
        className={`trip-item ${selectedTripId === trip.id ? 'selected' : ''}`}
        onClick={() => onSelectTrip(trip.id)}
      >
        {trip.numPeople && (
          <div className="people-count">
            <IoPeople style={{ verticalAlign: 'middle', marginRight: '3px' }} />
            {trip.numPeople}
          </div>
        )}
        <div className="trip-info">
          <div className="trip-name">{trip.location}</div>
          {dateStr && <div className="trip-dates">{dateStr}</div>}
        </div>
        <div className="trip-icons" onClick={(e) => e.stopPropagation()}>
          <button className="icon-btn" onClick={() => onEditTrip(trip)}>
            <IoPencil />
          </button>
          <button className="icon-btn" onClick={() => onDeleteTrip(trip.id)}>
            <IoTrash />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="trip-list-container" style={{ position: 'relative' }}>

      {/* Show the user menu in the top-right if user is set */}
      {user && (
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <UserMenu
            user={user}
            onSignOut={onSignOut}
            onShowLogin={onShowLogin}
          />
        </div>
      )}

      <h2 style={{ margin: '20px 0', textAlign: 'center' }}>Trips</h2>

      {trips.length === 0 ? (
        <div className="no-trips">No trips planned yet!</div>
      ) : (
        <div className="trip-list-sections">
          {/* Current / Upcoming Trips */}
          <div className="trip-section">
            <div
              className="trip-section-header"
              onClick={() => handleCollapseToggle('current')}
            >
              <span>Current / Upcoming trips</span>
              {collapsed.current ? <IoChevronUp /> : <IoChevronDown />}
            </div>
            {!collapsed.current && (
              <div className="trip-list">
                {currentTrips.length === 0 ? (
                  <div className="no-trips-in-section">
                    No current or upcoming trips.
                  </div>
                ) : (
                  currentTrips.map((trip) => renderTripItem(trip))
                )}
              </div>
            )}
          </div>

          {/* Past Trips */}
          <div className="trip-section">
            <div
              className="trip-section-header"
              onClick={() => handleCollapseToggle('past')}
            >
              <span>Past trips</span>
              {collapsed.past ? <IoChevronUp /> : <IoChevronDown />}
            </div>
            {!collapsed.past && (
              <div className="trip-list">
                {pastTrips.length === 0 ? (
                  <div className="no-trips-in-section">No past trips.</div>
                ) : (
                  pastTrips.map((trip) => renderTripItem(trip))
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <button className="create-trip-btn" onClick={onCreateNewTrip}>
        Create new trip
      </button>
    </div>
  );
}

export default TripList;
