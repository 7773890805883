// src/components/UserMenu.js
import React, { useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { FaUserCircle } from 'react-icons/fa';
import '../../styles/UserAuth/UserMenu.css';

function UserMenu({ user, onSignOut, onShowLogin }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSignOut = () => {
    if (user.uid === 'demo') {
      // Just call onSignOut if it's the demo user
      onSignOut();
    } else {
      // Otherwise sign out from Firebase
      signOut(auth).then(() => {
        onSignOut();
      });
    }
    setMenuOpen(false);
  };

  const handleLoginSignUp = () => {
    // If currently a demo user, sign out first so the login modal can appear
    if (user.uid === 'demo') {
      onSignOut();
    }
    onShowLogin();
    setMenuOpen(false);
  };

  return (
    <div className="user-menu-container">
      <div className="user-icon" onClick={handleMenuToggle}>
        <FaUserCircle size={24} />
      </div>
      {menuOpen && (
        <div className="user-menu-dropdown">
          {user.uid !== 'demo' ? (
            <>
              <div className="user-name">{user.displayName}</div>
              <div className="menu-item" onClick={handleSignOut}>
                Sign Out
              </div>
            </>
          ) : (
            <>
              <div className="user-name">Demo User</div>
              <div className="menu-item" onClick={handleLoginSignUp}>
                Log in / Sign up
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default UserMenu;
