import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD9hBa8SMdFWkhckHWdrnESpU8v2NdK5_w",
    authDomain: "travelplans-7f92d.firebaseapp.com",
    projectId: "travelplans-7f92d",
    storageBucket: "travelplans-7f92d.firebasestorage.app",
    messagingSenderId: "358525796534",
    appId: "1:358525796534:web:a00833151b1835f02ea617",
    measurementId: "G-HHH6Q6FS11"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);