// src/components/TripDetail/TripDetails.js
import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import '../../styles/TripDetail/TripDetails.css';

const TripDetails = ({ tripId, trip }) => {
  const [details, setDetails] = useState({
    flights: [],
    insurance: '',
    roaming: '',
    accommodation: '',
    notes: ''
  });
  const [editingFlightId, setEditingFlightId] = useState(null);

  const homeLocation = 'Singapore'; // Default home location, could be made configurable

  // Load details from Firestore
  useEffect(() => {
    const loadDetails = async () => {
      if (!tripId) return;
      
      try {
        const tripRef = doc(db, 'trips', tripId);
        const snapshot = await getDoc(tripRef);
        
        if (snapshot.exists()) {
          const data = snapshot.data();
          if (data.details) {
            // Convert legacy flight string to flights array if needed
            if (data.details.flight && !data.details.flights) {
              data.details.flights = [];
              if (data.details.flight.trim()) {
                data.details.flights.push({
                  id: Date.now(),
                  from: '',
                  to: '',
                  date: '',
                  flightNumber: '',
                  departureTime: '',
                  arrivalTime: '',
                  notes: data.details.flight
                });
              }
            }
            
            // Ensure flights is an array
            if (!data.details.flights) {
              data.details.flights = [];
            }
            
            setDetails(data.details);
          }
        }
      } catch (error) {
        console.error('Error loading trip details:', error);
      }
    };
    
    loadDetails();
  }, [tripId]);

  // Auto-populate flights based on trip sections
  useEffect(() => {
    // Only auto-populate if no flights exist yet
    if (details.flights.length === 0) {
      const { sections, startDate, endDate, location } = trip;
      const newFlights = [];
      
      if (sections && sections.length > 0) {
        // Calculate the trip sections in chronological order
        const orderedSections = [...sections].sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );

        // First flight: Home to first section
        const firstSection = orderedSections[0];
        if (firstSection) {
          newFlights.push({
            id: Date.now(),
            from: homeLocation,
            to: firstSection.location,
            date: firstSection.startDate,
            flightNumber: '',
            departureTime: '',
            arrivalTime: '',
            notes: ''
          });
        }

        // Inter-section flights
        for (let i = 0; i < orderedSections.length - 1; i++) {
          const fromSection = orderedSections[i];
          const toSection = orderedSections[i + 1];
          
          newFlights.push({
            id: Date.now() + i + 1,
            from: fromSection.location,
            to: toSection.location,
            date: toSection.startDate,
            flightNumber: '',
            departureTime: '',
            arrivalTime: '',
            notes: ''
          });
        }

        // Last flight: Last section to Home
        const lastSection = orderedSections[orderedSections.length - 1];
        if (lastSection) {
          newFlights.push({
            id: Date.now() + orderedSections.length,
            from: lastSection.location,
            to: homeLocation,
            date: lastSection.endDate,
            flightNumber: '',
            departureTime: '',
            arrivalTime: '',
            notes: ''
          });
        }
      } else {
        // Simple trip with no sections: just departure and return
        if (startDate && location) {
          // Departure flight
          newFlights.push({
            id: Date.now(),
            from: homeLocation,
            to: location,
            date: startDate,
            flightNumber: '',
            departureTime: '',
            arrivalTime: '',
            notes: ''
          });
        }
        
        if (endDate && location) {
          // Return flight
          newFlights.push({
            id: Date.now() + 1,
            from: location,
            to: homeLocation,
            date: endDate,
            flightNumber: '',
            departureTime: '',
            arrivalTime: '',
            notes: ''
          });
        }
      }

      if (newFlights.length > 0) {
        setDetails(prev => ({
          ...prev,
          flights: newFlights
        }));
      }
    }
  }, [trip, details.flights.length]);

  // Save details to Firestore
  const saveDetails = async () => {
    if (!tripId) return;
    
    try {
      const tripRef = doc(db, 'trips', tripId);
      await updateDoc(tripRef, { details });
      alert('Details saved successfully');
    } catch (error) {
      console.error('Error saving trip details:', error);
      alert('Error saving details');
    }
  };

  // Handle input changes for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle flight field changes
  const handleFlightChange = (id, field, value) => {
    setDetails(prev => ({
      ...prev,
      flights: prev.flights.map(flight => 
        flight.id === id ? { ...flight, [field]: value } : flight
      )
    }));
  };

  // Add a new flight
  const addFlight = () => {
    const newFlight = {
      id: Date.now(),
      from: '',
      to: '',
      date: '',
      flightNumber: '',
      departureTime: '',
      arrivalTime: '',
      notes: ''
    };
    
    setDetails(prev => ({
      ...prev,
      flights: [...prev.flights, newFlight]
    }));
    setEditingFlightId(newFlight.id); // Open modal for the new flight
  };

  // Remove a flight
  const removeFlight = (id) => {
    setDetails(prev => ({
      ...prev,
      flights: prev.flights.filter(flight => flight.id !== id)
    }));
    if (editingFlightId === id) {
      setEditingFlightId(null);
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
    } catch (e) {
      return '';
    }
  };

  // Format date for input field
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // YYYY-MM-DD format
    } catch (e) {
      return '';
    }
  };

  // Sort flights by date
  const sortedFlights = [...details.flights].sort((a, b) => {
    if (!a.date) return 1; // Put flights without dates at the end
    if (!b.date) return -1;
    return new Date(a.date) - new Date(b.date);
  });

  // Get short location name (e.g., "Guangzhou" from "Guangzhou, Guangdong Province, China")
  const getShortLocationName = (location) => {
    if (!location) return '';
    return location.split(',')[0].trim();
  };

  // Get shorter trip location name for header
  const getShortTripLocation = () => {
    if (!trip.location) return '';
    const parts = trip.location.split(',');
    return parts.length > 0 ? parts[0].trim() : trip.location;
  };

  return (
    <div className="trip-details-container">
      <div className="section-header trip-details-header">
        <h3>Trip Details</h3>
      </div>
      
      <div className="details-form">
        <div className="flights-section">
          <div className="section-header flight-details-header">
            <h3>Flight Details</h3>
            <button 
              className="add-flight-btn"
              onClick={addFlight}
              type="button"
            >
              + Add
            </button>
          </div>
          
          {details.flights.length === 0 ? (
            <p className="no-flights-message">No flights added yet.</p>
          ) : (
            <div className="flight-list">
              {sortedFlights.map((flight) => (
                <div 
                  key={flight.id} 
                  className="flight-list-item"
                  onClick={() => setEditingFlightId(flight.id)}
                >
                  <div className="flight-summary">
                    <span className="flight-route">
                      {getShortLocationName(flight.from)} ✈️ {getShortLocationName(flight.to)}
                    </span>
                    {flight.date && (
                      <span className="flight-date">{formatDate(flight.date)}</span>
                    )}
                    {flight.flightNumber && (
                      <span className="flight-number">{flight.flightNumber}</span>
                    )}
                  </div>
                  <button 
                    className="remove-flight-btn"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent opening the modal
                      removeFlight(flight.id);
                    }}
                    type="button"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Flight Edit Modal */}
          {editingFlightId && (
            <div className="flight-modal-overlay" onClick={() => setEditingFlightId(null)}>
              <div className="flight-modal" onClick={(e) => e.stopPropagation()}>
                <div className="flight-modal-header">
                  <h3>Edit Flight Details</h3>
                  <button 
                    className="close-modal-btn"
                    onClick={() => setEditingFlightId(null)}
                  >
                    &times;
                  </button>
                </div>
                
                {(() => {
                  const flight = details.flights.find(f => f.id === editingFlightId);
                  if (!flight) return null;
                  
                  return (
                    <div className="flight-modal-content">
                      <div className="flight-details-grid">
                        <div className="form-group">
                          <label>From</label>
                          <input
                            type="text"
                            value={flight.from}
                            onChange={(e) => handleFlightChange(flight.id, 'from', e.target.value)}
                            placeholder="Departure location"
                          />
                        </div>
                        
                        <div className="form-group">
                          <label>To</label>
                          <input
                            type="text"
                            value={flight.to}
                            onChange={(e) => handleFlightChange(flight.id, 'to', e.target.value)}
                            placeholder="Arrival location"
                          />
                        </div>
                        
                        <div className="form-group">
                          <label>Date</label>
                          <input
                            type="date"
                            value={formatDateForInput(flight.date)}
                            onChange={(e) => handleFlightChange(flight.id, 'date', e.target.value)}
                          />
                        </div>
                        
                        <div className="form-group">
                          <label>Flight Number</label>
                          <input
                            type="text"
                            value={flight.flightNumber}
                            onChange={(e) => handleFlightChange(flight.id, 'flightNumber', e.target.value)}
                            placeholder="e.g. SQ123"
                          />
                        </div>
                        
                        <div className="form-group">
                          <label>Departure Time</label>
                          <input
                            type="time"
                            value={flight.departureTime}
                            onChange={(e) => handleFlightChange(flight.id, 'departureTime', e.target.value)}
                          />
                        </div>
                        
                        <div className="form-group">
                          <label>Arrival Time</label>
                          <input
                            type="time"
                            value={flight.arrivalTime}
                            onChange={(e) => handleFlightChange(flight.id, 'arrivalTime', e.target.value)}
                          />
                        </div>
                      </div>
                      
                      <div className="form-group">
                        <label>Notes</label>
                        <textarea
                          value={flight.notes}
                          onChange={(e) => handleFlightChange(flight.id, 'notes', e.target.value)}
                          placeholder="Booking references, baggage allowance, etc."
                          rows="3"
                        />
                      </div>
                      
                      <div className="flight-modal-footer">
                        <button 
                          className="save-modal-btn"
                          onClick={() => setEditingFlightId(null)}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  );
                })()} 
              </div>
            </div>
          )}
        </div>
        
        <div className="form-group">
          <label htmlFor="accommodation">Accommodation Details</label>
          <textarea
            id="accommodation"
            name="accommodation"
            value={details.accommodation}
            onChange={handleChange}
            placeholder="Hotel bookings, addresses, check-in information..."
            rows="3"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="insurance">Insurance Information</label>
          <textarea
            id="insurance"
            name="insurance"
            value={details.insurance}
            onChange={handleChange}
            placeholder="Policy numbers, emergency contacts, coverage details..."
            rows="3"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="roaming">Phone & Roaming Details</label>
          <textarea
            id="roaming"
            name="roaming"
            value={details.roaming}
            onChange={handleChange}
            placeholder="Mobile plan details, roaming packages, local SIM information..."
            rows="3"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="notes">Additional Notes</label>
          <textarea
            id="notes"
            name="notes"
            value={details.notes}
            onChange={handleChange}
            placeholder="Any other important information to remember..."
            rows="4"
          />
        </div>
        
        <button 
          className="save-details-btn"
          onClick={saveDetails}
        >
          Save Details
        </button>
      </div>
    </div>
  );
};

export default TripDetails;