// src/suggestionsService.js
import { db } from './firebase';
import { 
  doc, 
  setDoc, 
  getDoc, 
  serverTimestamp, 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc,
  increment,
  arrayUnion
} from "firebase/firestore";
import { OPENAI_API_KEY } from './config';

const CATEGORIES = ["Attractions", "Food", "Accommodations"];

// In-memory cache to store suggestions per tripId+category
const suggestionsCache = new Map();

async function fetchSuggestionsFromOpenAI(category, tripLocation) {
  if (!tripLocation) {
    console.warn(`[fetchSuggestionsFromOpenAI] tripLocation is undefined for category ${category}. Using default "Unknown Location".`);
    tripLocation = "Unknown Location";
  }
  console.log(`[fetchSuggestionsFromOpenAI] Starting OpenAI call for category: ${category}, tripLocation: ${tripLocation}`);
  
  const userPrompt = `List 5 popular ${category.toLowerCase()} places in ${tripLocation} with a short description for each. Respond in JSON format as an array of objects with keys "name" and "description".`;
  
  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${OPENAI_API_KEY}`,
      },
      body: JSON.stringify({
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: 'You are a helpful travel assistant. You will only respond with JSON.'
          },
          {
            role: 'user',
            content: userPrompt
          }
        ],
        max_tokens: 1000,
        temperature: 0.7,
      }),
    });

    if (!response.ok) {
      console.log(`[fetchSuggestionsFromOpenAI] OpenAI API error for category ${category}: ${response.status} ${response.statusText}`);
      throw new Error(`OpenAI API error: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    console.log(`[fetchSuggestionsFromOpenAI] OpenAI response received for category ${category}:`, data);

    let text = data.choices[0].message.content.trim();
    // Remove Markdown fences if present
    text = text.replace(/```(json)?/gi, '').replace(/```/g, '').trim();
    let suggestionsData = [];
    try {
      suggestionsData = JSON.parse(text);
    } catch (parseError) {
      console.warn(`[fetchSuggestionsFromOpenAI] JSON parse error for category ${category}, attempting partial fix...`, parseError);
      const lastBrace = text.lastIndexOf(']');
      if (lastBrace !== -1) {
        const fixedJson = text.slice(0, lastBrace + 1);
        suggestionsData = JSON.parse(fixedJson);
      } else {
        throw new Error("Error parsing API response");
      }
    }
    
    // Removed photo enrichment to save costs
    console.log(`[fetchSuggestionsFromOpenAI] Suggestions generated for category ${category}:`, suggestionsData);
    return suggestionsData;
  } catch (error) {
    console.error(`[fetchSuggestionsFromOpenAI] Error in OpenAI call for category ${category}:`, error);
    throw error;
  }
}

// Helper to fetch a photo URL using the Google Maps Places Service
// Commented out to save costs but kept for future reference
/*
function fetchPhotoForPlace(placeName) {
  return new Promise((resolve) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    const request = {
      query: placeName,
      fields: ["photos"]
    };
    
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && results && results[0] && results[0].photos && results[0].photos.length > 0) {
        resolve(results[0].photos[0].getUrl({ maxWidth: 400, maxHeight: 400 }));
      } else {
        resolve(null);
      }
    });
  });
}
*/

// New function to check if location exists in global locations database
async function checkLocationExists(location) {
  console.log(`[checkLocationExists] Checking if location exists: ${location}`);
  const locationId = location.toLowerCase().replace(/\s+/g, '-');
  const locationRef = doc(db, "locations", locationId);
  const docSnap = await getDoc(locationRef);
  
  return docSnap.exists();
}

// New function to add a location to the global database
async function addLocationToGlobalDatabase(location) {
  console.log(`[addLocationToGlobalDatabase] Adding location to global database: ${location}`);
  const locationRef = doc(db, "locations", location.toLowerCase().replace(/\s+/g, '-'));
  
  // Check if location already exists
  const docSnap = await getDoc(locationRef);
  
  if (docSnap.exists()) {
    console.log(`[addLocationToGlobalDatabase] Location ${location} already exists, skipping`);
    return;
  }
  
  // Create the location with empty category collections
  await setDoc(locationRef, {
    name: location,
    createdAt: serverTimestamp(),
    // Initialize empty collections for each category
    Attractions: [],
    Food: [],
    Accommodations: [],
    Others: []
  });
  console.log(`[addLocationToGlobalDatabase] Created new location: ${location}`);
}

// New function to add suggestions to the global database under the location
async function saveSuggestionsToGlobalDatabase(location, category, suggestions) {
  console.log(`[saveSuggestionsToGlobalDatabase] Saving ${suggestions.length} suggestions for ${category} in ${location}`);
  
  const locationRef = doc(db, "locations", location.toLowerCase().replace(/\s+/g, '-'));
  const docSnap = await getDoc(locationRef);
  
  if (!docSnap.exists()) {
    console.log(`[saveSuggestionsToGlobalDatabase] Location ${location} does not exist, creating it first`);
    await addLocationToGlobalDatabase(location);
  }
  
  // Get current category places
  const locationData = (await getDoc(locationRef)).data();
  const currentPlaces = locationData[category] || [];
  
  // Create a map of existing place names for quick lookup
  const existingPlaceNames = new Map();
  currentPlaces.forEach(place => {
    existingPlaceNames.set(place.name.toLowerCase(), place);
  });
  
  // Process new suggestions
  const updatedPlaces = [...currentPlaces];
  
  for (const suggestion of suggestions) {
    const placeNameLower = suggestion.name.toLowerCase();
    
    // Skip if this place already exists
    if (existingPlaceNames.has(placeNameLower)) {
      console.log(`[saveSuggestionsToGlobalDatabase] Place ${suggestion.name} already exists in ${location}, skipping`);
      continue;
    }
    
    // Add the new place to the array
    updatedPlaces.push({
      name: suggestion.name,
      description: suggestion.description,
      category: category,
      userCount: 0,
      generatedAt: new Date().toISOString(), // Use string timestamp instead of serverTimestamp()
      isAIGenerated: true,
      users: []
    });
    
    console.log(`[saveSuggestionsToGlobalDatabase] Added new place: ${suggestion.name}`);
  }
  
  // Update the location document with the new places array
  await updateDoc(locationRef, {
    [category]: updatedPlaces,
    lastUpdated: serverTimestamp()
  });
  
  console.log(`[saveSuggestionsToGlobalDatabase] Updated ${category} for ${location} with ${updatedPlaces.length} places`);
}

// Function to update place recommendation when user adds a place
export async function updateCommunityRecommendation(userId, place, category, location) {
  if (!userId || !place || !category || !location) {
    console.error("[updateCommunityRecommendation] Missing required parameters:", { userId, place, category, location });
    return;
  }

  console.log(`[updateCommunityRecommendation] Updating recommendation for ${place.name} in ${location}`);
  
  const locationId = location.toLowerCase().replace(/\s+/g, '-');
  const locationRef = doc(db, "locations", locationId);
  const docSnap = await getDoc(locationRef);
  
  // If location doesn't exist, create it first
  if (!docSnap.exists()) {
    console.log(`[updateCommunityRecommendation] Location ${location} doesn't exist, creating it`);
    await addLocationToGlobalDatabase(location);
  }
  
  // Get current data
  const locationData = (await getDoc(locationRef)).data();
  const categoryPlaces = locationData[category] || [];
  
  // Look for existing place by name
  const placeIndex = categoryPlaces.findIndex(p => 
    p.name.toLowerCase() === place.name.toLowerCase());
  
  if (placeIndex >= 0) {
    // Place exists, update it
    const existingPlace = categoryPlaces[placeIndex];
    
    // Check if this user has already added this place
    if (!existingPlace.users || !existingPlace.users.includes(userId)) {
      // Update the place in the array
      categoryPlaces[placeIndex] = {
        ...existingPlace,
        userCount: (existingPlace.userCount || 0) + 1,
        users: [...(existingPlace.users || []), userId],
        lat: place.lat || existingPlace.lat,
        lng: place.lng || existingPlace.lng
      };
      
      // Update in Firestore
      await updateDoc(locationRef, {
        [category]: categoryPlaces,
        lastUpdated: serverTimestamp()
      });
      
      console.log(`[updateCommunityRecommendation] Updated count for ${place.name} in ${location}`);
    } else {
      console.log(`[updateCommunityRecommendation] User ${userId} already added ${place.name}, not incrementing`);
    }
  } else {
    // Place doesn't exist, add it
    categoryPlaces.push({
      name: place.name,
      description: place.description || `A popular ${category.toLowerCase()} option in ${location}`,
      category: category,
      userCount: 1,
      lat: place.lat,
      lng: place.lng,
      generatedAt: new Date().toISOString(), // Use string timestamp instead of serverTimestamp()
      isAIGenerated: false, // This is from autocomplete/user input
      users: [userId]
    });
    
    // Update in Firestore
    await updateDoc(locationRef, {
      [category]: categoryPlaces,
      lastUpdated: serverTimestamp()
    });
    
    console.log(`[updateCommunityRecommendation] Added new place ${place.name} to ${location}`);
  }
}

// Function to remove a specific place recommendation for a user
export async function removePlaceRecommendation(userId, placeName, category, location) {
  if (!userId || !placeName || !category || !location) {
    console.error("[removePlaceRecommendation] Missing required parameters:", { userId, placeName, category, location });
    return;
  }

  console.log(`[removePlaceRecommendation] Removing recommendation for place "${placeName}" for user ${userId} in ${location}`);
  
  const locationId = location.toLowerCase().replace(/\s+/g, '-');
  const locationRef = doc(db, "locations", locationId);
  const docSnap = await getDoc(locationRef);
  
  // If location doesn't exist, nothing to remove
  if (!docSnap.exists()) {
    console.log(`[removePlaceRecommendation] Location ${location} doesn't exist, nothing to remove`);
    return;
  }
  
  // Get location data
  const locationData = docSnap.data();
  
  // Get the places for this category
  const categoryPlaces = locationData[category] || [];
  
  // Find the place by name
  const placeIndex = categoryPlaces.findIndex(p => 
    p.name.toLowerCase() === placeName.toLowerCase());
  
  // If place doesn't exist in this category, return
  if (placeIndex === -1) {
    console.log(`[removePlaceRecommendation] Place "${placeName}" not found in ${category} for ${location}`);
    return;
  }
  
  const place = categoryPlaces[placeIndex];
  
  // If the place doesn't have this user in its users array, return
  if (!place.users || !place.users.includes(userId)) {
    console.log(`[removePlaceRecommendation] User ${userId} not found in users for place "${placeName}"`);
    return;
  }
  
  // Update user count and remove user from the users array
  const updatedUsers = place.users.filter(id => id !== userId);
  const updatedUserCount = (place.userCount || 1) - 1;
  
  // Create the updated places array
  const updatedCategoryPlaces = [...categoryPlaces];
  
  // If there are still users for this place, update it
  if (updatedUserCount > 0) {
    updatedCategoryPlaces[placeIndex] = {
      ...place,
      userCount: updatedUserCount,
      users: updatedUsers
    };
  } else {
    // If the place is AI-generated, keep it but with userCount 0
    if (place.isAIGenerated) {
      updatedCategoryPlaces[placeIndex] = {
        ...place,
        userCount: 0,
        users: []
      };
    } else {
      // Otherwise, remove the place from the array
      updatedCategoryPlaces.splice(placeIndex, 1);
    }
  }
  
  // Update the location document
  await updateDoc(locationRef, {
    [category]: updatedCategoryPlaces,
    lastUpdated: serverTimestamp()
  });
  
  console.log(`[removePlaceRecommendation] Updated recommendation for place "${placeName}" in ${location}`);
}

// Function to remove all user's recommendations when their trip is deleted
export async function removeCommunityRecommendations(userId, location) {
  if (!userId || !location) {
    console.error("[removeCommunityRecommendations] Missing required parameters:", { userId, location });
    return;
  }

  console.log(`[removeCommunityRecommendations] Removing recommendations for user ${userId} in ${location}`);
  
  const locationId = location.toLowerCase().replace(/\s+/g, '-');
  const locationRef = doc(db, "locations", locationId);
  const docSnap = await getDoc(locationRef);
  
  // If location doesn't exist, nothing to remove
  if (!docSnap.exists()) {
    console.log(`[removeCommunityRecommendations] Location ${location} doesn't exist, nothing to remove`);
    return;
  }
  
  // Get location data
  const locationData = docSnap.data();
  let updated = false;
  
  // Process each category
  for (const category of ["Attractions", "Food", "Accommodations", "Others"]) {
    const categoryPlaces = locationData[category] || [];
    const updatedCategoryPlaces = [];
    
    // Process each place in the category
    for (const place of categoryPlaces) {
      // Skip places without a users array
      if (!place.users) {
        updatedCategoryPlaces.push(place);
        continue;
      }
      
      // Check if this user has this place in their recommendations
      if (place.users.includes(userId)) {
        // Update user count and remove user from the users array
        const updatedUsers = place.users.filter(id => id !== userId);
        const updatedUserCount = (place.userCount || 1) - 1;
        
        // If there are still users for this place, update it
        if (updatedUserCount > 0) {
          updatedCategoryPlaces.push({
            ...place,
            userCount: updatedUserCount,
            users: updatedUsers
          });
        } else {
          // If the place is AI-generated, keep it but with userCount 0
          if (place.isAIGenerated) {
            updatedCategoryPlaces.push({
              ...place,
              userCount: 0,
              users: []
            });
          }
          // Otherwise, if it was user-added and no users left, it's removed by not adding to updatedCategoryPlaces
        }
        
        updated = true;
      } else {
        // User didn't have this place, keep it as is
        updatedCategoryPlaces.push(place);
      }
    }
    
    // Update the category's places array
    if (categoryPlaces.length !== updatedCategoryPlaces.length || 
        JSON.stringify(categoryPlaces) !== JSON.stringify(updatedCategoryPlaces)) {
      locationData[category] = updatedCategoryPlaces;
      updated = true;
    }
  }
  
  // Update the location document if changes were made
  if (updated) {
    await updateDoc(locationRef, {
      ...locationData,
      lastUpdated: serverTimestamp()
    });
    console.log(`[removeCommunityRecommendations] Updated recommendations for ${location}`);
  } else {
    console.log(`[removeCommunityRecommendations] No changes needed for ${location}`);
  }
}

async function generateSuggestionsForCategory(tripId, tripLocation, category, sectionId = null) {
  // If sectionId is provided, we're generating suggestions for a specific section
  const pathSuffix = sectionId ? `/sections/${sectionId}` : '';
  console.log(`[generateSuggestionsForCategory] Generating suggestions for category: ${category} for tripId: ${tripId}${pathSuffix}`);
  
  // Check if location exists in the global database
  const locationExists = await checkLocationExists(tripLocation);
  let suggestions;
  let fromGlobalDatabase = false;
  
  if (locationExists) {
    console.log(`[generateSuggestionsForCategory] ${tripLocation} exists in global database, fetching location data`);
    // Fetch location data from the locations collection
    const locationRef = doc(db, "locations", tripLocation.toLowerCase().replace(/\s+/g, '-'));
    const locationDoc = await getDoc(locationRef);
    
    if (locationDoc.exists()) {
      const locationData = locationDoc.data();
      
      // Get places for this category
      const categoryPlaces = locationData[category] || [];
      
      if (categoryPlaces.length > 0) {
        suggestions = categoryPlaces.map(place => ({
          name: place.name,
          description: place.description,
          category: place.category,
          userCount: place.userCount || 0,
          isAIGenerated: place.isAIGenerated || false
        }));
        
        fromGlobalDatabase = true;
        console.log(`[generateSuggestionsForCategory] Found ${suggestions.length} suggestions for ${category} in ${tripLocation}`);
      }
    }
  }
  
  // If location doesn't exist or no suggestions, generate with AI
  if (!suggestions || suggestions.length === 0) {
    console.log(`[generateSuggestionsForCategory] No existing suggestions found, generating with AI for ${category} in ${tripLocation}`);
    suggestions = await fetchSuggestionsFromOpenAI(category, tripLocation);
    
    // Add the location to the global database if it doesn't exist
    if (!locationExists) {
      await addLocationToGlobalDatabase(tripLocation);
    }
    
    // Add suggestions to the global database
    await saveSuggestionsToGlobalDatabase(tripLocation, category, suggestions);
  }
  
  // Determine the correct path for the suggestions
  let suggestionDocRef;
  if (sectionId) {
    // For section-specific suggestions
    suggestionDocRef = doc(db, "trips", tripId, "sections", sectionId, "suggestions", category);
  } else {
    // For main trip suggestions
    suggestionDocRef = doc(db, "trips", tripId, "suggestions", category);
  }
  
  // Save the suggestions to Firestore
  await setDoc(suggestionDocRef, {
    suggestions,
    createdAt: serverTimestamp(),
    fromGlobalDatabase
  });
  
  console.log(`[generateSuggestionsForCategory] Suggestions saved for category: ${category} for tripId: ${tripId}${pathSuffix}`);
  return suggestions;
}

export async function generateSuggestionsForTrip(tripId, tripLocation, sectionId = null) {
  // If sectionId is provided, we're generating suggestions for a specific section
  const pathSuffix = sectionId ? `/sections/${sectionId}` : '';
  console.log(`[generateSuggestionsForTrip] Generating suggestions for tripId: ${tripId}${pathSuffix} with tripLocation: ${tripLocation}`);
  
  // Generate suggestions concurrently for each category
  const promises = CATEGORIES.map(category =>
    generateSuggestionsForCategory(tripId, tripLocation, category, sectionId)
      .catch(err => {
        console.error(`[generateSuggestionsForTrip] Error generating suggestions for ${category}:`, err);
        return null;
      })
  );
  return Promise.all(promises);
}

export async function checkAndRefreshSuggestions(tripId, tripLocation, category, sectionId = null) {
  if (!tripLocation) {
    console.warn(`[checkAndRefreshSuggestions] tripLocation is undefined for tripId: ${tripId}, category: ${category}. Using default "Unknown Location".`);
    tripLocation = "Unknown Location";
  }
  
  // If sectionId is provided, we're checking suggestions for a specific section
  const pathSuffix = sectionId ? `/sections/${sectionId}` : '';
  const cacheKey = `${tripId}${pathSuffix}_${category}`;
  const now = new Date();
  
  if (suggestionsCache.has(cacheKey)) {
    const cached = suggestionsCache.get(cacheKey);
    if (now - cached.createdAt < 24 * 60 * 60 * 1000) {
      console.log(`[checkAndRefreshSuggestions] Returning cached suggestions for ${category}${pathSuffix}`);
      return {
        suggestions: cached.suggestions,
        generatedDate: cached.createdAt
      };
    } else {
      suggestionsCache.delete(cacheKey);
    }
  }
  
  console.log(`[checkAndRefreshSuggestions] Checking suggestions for category: ${category} for tripId: ${tripId}${pathSuffix} with tripLocation: ${tripLocation}`);
  
  // Determine the correct path for the suggestions
  let suggestionDocRef;
  if (sectionId) {
    // For section-specific suggestions
    suggestionDocRef = doc(db, "trips", tripId, "sections", sectionId, "suggestions", category);
  } else {
    // For main trip suggestions
    suggestionDocRef = doc(db, "trips", tripId, "suggestions", category);
  }
  
  const docSnap = await getDoc(suggestionDocRef);
  
  if (docSnap.exists()) {
    const data = docSnap.data();
    const createdAt = data.createdAt ? data.createdAt.toDate() : null;
    console.log(`[checkAndRefreshSuggestions] Suggestions document found for category: ${category}${pathSuffix}. CreatedAt: ${createdAt}`);
    
    // If there are no suggestions yet or the data is older than 24 hours, refresh it.
    if (!data.suggestions || (createdAt && now - createdAt > 24 * 60 * 60 * 1000)) {
      console.log(`[checkAndRefreshSuggestions] Suggestions for ${category}${pathSuffix} are missing or older than 24 hours. Refreshing...`);
      const suggestions = await generateSuggestionsForCategory(tripId, tripLocation, category, sectionId);
      const newCreatedAt = new Date();
      suggestionsCache.set(cacheKey, { suggestions, createdAt: newCreatedAt });
      return {
        suggestions,
        generatedDate: newCreatedAt
      };
    }
    
    console.log(`[checkAndRefreshSuggestions] Using existing suggestions for ${category}${pathSuffix}`);
    suggestionsCache.set(cacheKey, { suggestions: data.suggestions, createdAt });
    return {
      suggestions: data.suggestions,
      generatedDate: createdAt
    };
  } else {
    // No document exists; generate new suggestions.
    console.log(`[checkAndRefreshSuggestions] No suggestions document for ${category}${pathSuffix}. Generating new suggestions...`);
    const suggestions = await generateSuggestionsForCategory(tripId, tripLocation, category, sectionId);
    const newCreatedAt = new Date();
    suggestionsCache.set(cacheKey, { suggestions, createdAt: newCreatedAt });
    return {
      suggestions,
      generatedDate: newCreatedAt
    };
  }
}
