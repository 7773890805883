// src/components/TripDetail/TripPlaces.js
import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import {
  IoTrash,
  IoDocumentText,
  IoStar,
  IoStarOutline,
  IoChevronDown,
  IoChevronUp,
  IoArrowForward,
  IoBulbOutline
} from 'react-icons/io5';
import '../../styles/TripDetail/TripPlaces.css';
import { updateDoc, doc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import { checkAndRefreshSuggestions } from '../../suggestionsService';

const CATEGORIES = ["Attractions", "Food", "Accommodations", "Others"];

function linkify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, i) => {
    if (urlRegex.test(part)) {
      return (
        <a key={i} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

function TripPlaces({
  tripId,
  tripLocation, // expected to be provided from TripDetail
  bounds,
  autocompleteRef,
  inputValue,
  setInputValue,
  onPlaceChanged,
  places,
  selectedPlaceId,
  onSelectPlace,
  collapsed,
  setCollapsed,
  handleCategoryClick,
  categoryPlaces,
  handleDeletePlace,
  toggleMustGo,
  isMustGo,
  notesPlaceId,
  toggleNotes,
  tempNotes,
  setTempNotes,
  saveNotes,
  isEditingNotes,
  startEditingNotes,
  hasNotes,
  onMenuClick,
  isMobile,
  isInItinerary,
  itineraryDays = [],
  onAddPlaceToItinerary,
  onInspirationClick, // callback with (category, suggestions, error, loading)
  onPlaceDoubleClick,
  activeSection // The currently active section ID
}) {
  const [localCategoryPlaces, setLocalCategoryPlaces] = useState(categoryPlaces);
  const [dropdownPlaceId, setDropdownPlaceId] = useState(null);

  // Ensure we have a valid tripLocation
  const effectiveTripLocation = tripLocation || "Unknown Location";
  if (!tripLocation) {
    console.warn("[TripPlaces] Warning: tripLocation prop is undefined. Using default:", effectiveTripLocation);
  }

  useEffect(() => {
    const sorted = {};
    Object.keys(categoryPlaces).forEach(cat => {
      sorted[cat] = [...categoryPlaces[cat]].sort((a, b) => {
        const aOrder = typeof a.order === 'number' ? a.order : 0;
        const bOrder = typeof b.order === 'number' ? b.order : 0;
        return aOrder - bOrder;
      });
    });
    setLocalCategoryPlaces(sorted);
  }, [categoryPlaces]);

  const [dragOverItem, setDragOverItem] = useState(null);

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e, placeId, category, index) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({ source: 'placeList', placeId, category, index })
    );
  };

  const handleDragOverItem = (e, category, index) => {
    e.preventDefault();
    setDragOverItem({ category, index });
  };

  const handleDragLeaveItem = () => {
    setDragOverItem(null);
  };

  const commitCategoryOrder = (category, items) => {
    const batch = writeBatch(db);
    items.forEach((item, index) => {
      if (item.order !== index) {
        const docRef = doc(db, "trips", tripId, "places", item.id);
        batch.update(docRef, { order: index });
      }
    });
    batch.commit().catch((error) => console.error("Error updating order: ", error));
  };

  const handleDropOnItem = (e, targetCategory, targetIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('application/json');
    if (!data) return;
    try {
      const parsed = JSON.parse(data);
      if (parsed.source === 'placeList' && parsed.placeId) {
        const sourceCategory = parsed.category;
        const sourceIndex = parsed.index;
        const newLocal = { ...localCategoryPlaces };

        if (sourceCategory === targetCategory) {
          const items = Array.from(newLocal[sourceCategory] || []);
          const [movedItem] = items.splice(sourceIndex, 1);
          items.splice(targetIndex, 0, movedItem);
          newLocal[sourceCategory] = items;
          setLocalCategoryPlaces(newLocal);
          commitCategoryOrder(targetCategory, items);
        } else {
          const sourceItems = Array.from(newLocal[sourceCategory] || []);
          const targetItems = Array.from(newLocal[targetCategory] || []);
          const [movedItem] = sourceItems.splice(sourceIndex, 1);
          movedItem.category = targetCategory;
          targetItems.splice(targetIndex, 0, movedItem);
          newLocal[sourceCategory] = sourceItems;
          newLocal[targetCategory] = targetItems;
          setLocalCategoryPlaces(newLocal);
          commitCategoryOrder(sourceCategory, sourceItems);
          commitCategoryOrder(targetCategory, targetItems);
          updateDoc(doc(db, "trips", tripId, "places", movedItem.id), {
            category: targetCategory
          });
        }
        setDragOverItem(null);
      }
    } catch (err) {
      console.error("Error parsing drag data", err);
    }
  };

  const handleDropOnCategoryEmpty = (e, category) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('application/json');
    if (!data) return;
    try {
      const parsed = JSON.parse(data);
      if (parsed.source === 'placeList' && parsed.placeId) {
        const sourceCategory = parsed.category;
        const sourceIndex = parsed.index;
        const newLocal = { ...localCategoryPlaces };
        const targetItems = Array.from(newLocal[category] || []);
        if (sourceCategory === category) {
          const items = Array.from(targetItems);
          const [movedItem] = items.splice(sourceIndex, 1);
          items.push(movedItem);
          newLocal[category] = items;
          setLocalCategoryPlaces(newLocal);
          commitCategoryOrder(category, items);
        } else {
          const sourceItems = Array.from(newLocal[sourceCategory] || []);
          const targetItems = Array.from(newLocal[category] || []);
          const [movedItem] = sourceItems.splice(sourceIndex, 1);
          movedItem.category = category;
          targetItems.push(movedItem);
          newLocal[sourceCategory] = sourceItems;
          newLocal[category] = targetItems;
          setLocalCategoryPlaces(newLocal);
          commitCategoryOrder(sourceCategory, sourceItems);
          commitCategoryOrder(category, targetItems);
          updateDoc(doc(db, "trips", tripId, "places", movedItem.id), {
            category: category
          });
        }
        setDragOverItem(null);
      }
    } catch (err) {
      console.error("Error parsing drag data", err);
    }
  };

  const onDropOnCategory = (e, category) => {
    if (e.target === e.currentTarget) {
      handleDropOnCategoryEmpty(e, category);
    }
  };

  const handleDropdownSelect = (placeId, dayIndex) => {
    if (onAddPlaceToItinerary) {
      onAddPlaceToItinerary(placeId, dayIndex);
    }
    setDropdownPlaceId(null);
  };

  // Modified inspiration handler to signal loading immediately and handle new structure.
  const handleInspirationClick = async (category) => {
    console.log(`[TripPlaces] Inspiration icon clicked for category: ${category} with section: ${activeSection || 'main'}`);
    // Immediately signal loading
    onInspirationClick(category, null, null, true);
    try {
      console.log(`[TripPlaces] Checking suggestions in Firestore for category: ${category}`);
      
      // Get the correct section ID from the activeSection if present
      const sectionId = activeSection ? activeSection : null;
      
      // Pass the section ID to get section-specific suggestions
      const result = await checkAndRefreshSuggestions(tripId, effectiveTripLocation, category, sectionId);
      
      console.log(`[TripPlaces] Suggestions retrieved for ${category} in ${effectiveTripLocation} (section: ${sectionId || 'main'}):`, result);
      // Pass both suggestions and generatedDate to the parent component
      onInspirationClick(category, result.suggestions, null, false, result.generatedDate);
    } catch (error) {
      console.error("[TripPlaces] Error handling inspiration click:", error);
      onInspirationClick(category, null, error.message, false, null);
    }
  };

  return (
    <div className="places-list-container">
      {isMobile && onMenuClick && (
        <button className="menu-btn" onClick={onMenuClick}>
          {/* Optional icon */}
        </button>
      )}

      <div className="autocomplete-container">
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
          options={{
            bounds: bounds,
            strictBounds: false,
            sessionToken: new window.google.maps.places.AutocompleteSessionToken(), // Add session token
          }}
        >
          <input
            type="text"
            placeholder="Search for places"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              boxSizing: 'border-box',
              border: '1px solid #ccc',
              borderRadius: '3px',
              outline: 'none'
            }}
          />
        </Autocomplete>
      </div>

      <div className="category-list">
        {CATEGORIES.map((cat) => {
          const items = localCategoryPlaces[cat] || [];
          return (
            <div
              key={cat}
              className="category-section"
              onDragOver={onDragOver}
              onDrop={(e) => onDropOnCategory(e, cat)}
            >
              <div className="category-header" onClick={() => handleCategoryClick(cat)}>
                <div className="category-header-left">
                  <span className="category-text">{cat}</span>
                  {/* Only show inspiration icon if not "Others" */}
                  {cat !== "Others" && (
                    <button
                      className="icon-btn idea-btn"
                      title={`Get ${cat} ideas`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInspirationClick(cat);
                      }}
                    >
                      <IoBulbOutline />
                    </button>
                  )}
                </div>
                <div className="category-header-right">
                  {collapsed[cat] ? <IoChevronUp /> : <IoChevronDown />}
                </div>
              </div>

              {!collapsed[cat] && items.length > 0 && (
                <ul
                  className="places-list"
                  onDragOver={onDragOver}
                  onDrop={(e) => {
                    if (e.target === e.currentTarget) {
                      handleDropOnCategoryEmpty(e, cat);
                    }
                  }}
                >
                  {items.map((p, index) => {
                    const showNotes = notesPlaceId === p.id;
                    const isDragOver =
                      dragOverItem &&
                      dragOverItem.category === cat &&
                      dragOverItem.index === index;
                    return (
                      <li
                        key={p.id}
                        data-id={p.id}
                        className={`
                          ${selectedPlaceId === p.id ? 'selected-place' : ''} 
                          ${isInItinerary(p.id) ? 'in-itinerary' : ''}
                          ${isDragOver ? 'drag-over' : ''}
                        `}
                        draggable
                        onDragStart={(e) => handleDragStart(e, p.id, cat, index)}
                        onDragOver={(e) => handleDragOverItem(e, cat, index)}
                        onDragLeave={handleDragLeaveItem}
                        onDrop={(e) => handleDropOnItem(e, cat, index)}
                        onClick={() => onSelectPlace(p.id)}
                        onDoubleClick={(e) => {
                          e.stopPropagation();
                          if (onPlaceDoubleClick) onPlaceDoubleClick(p);
                        }}
                      >
                        <div className="place-row-top">
                          <div className="place-info">
                            <div className="place-title-row">
                              <div className="place-name">{p.name}</div>
                              <button
                                className={`icon-btn notes-icon ${
                                  hasNotes(p) ? 'has-notes' : 'no-notes'
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleNotes(p);
                                }}
                              >
                                <IoDocumentText />
                              </button>
                            </div>
                            {(p.startDate || p.endDate) && (
                              <div className="place-dates">
                                {p.startDate && p.endDate
                                  ? `${p.startDate} - ${p.endDate}`
                                  : p.startDate
                                  ? `${p.startDate} -`
                                  : `- ${p.endDate}`}
                              </div>
                            )}
                          </div>
                          <div className="place-actions">
                            <button
                              className="icon-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleMustGo(p);
                              }}
                            >
                              {isMustGo(p) ? (
                                <IoStar style={{ color: '#f5d742' }} />
                              ) : (
                                <IoStarOutline />
                              )}
                            </button>
                            <button
                              className="icon-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDropdownPlaceId(
                                  dropdownPlaceId === p.id ? null : p.id
                                );
                              }}
                            >
                              <IoArrowForward />
                            </button>
                            <button
                              className="icon-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeletePlace(p.id);
                              }}
                            >
                              <IoTrash />
                            </button>
                          </div>
                        </div>

                        {showNotes && (
                          <div
                            className="inline-notes"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {isEditingNotes ? (
                              <>
                                <textarea
                                  className="notes-textarea"
                                  value={tempNotes}
                                  onChange={(e) => setTempNotes(e.target.value)}
                                />
                                <button
                                  className="save-notes-btn"
                                  onClick={saveNotes}
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <div
                                className="notes-content"
                                onClick={startEditingNotes}
                              >
                                {tempNotes.trim().length > 0
                                  ? linkify(tempNotes)
                                  : 'No notes yet. Click to edit.'}
                              </div>
                            )}
                          </div>
                        )}

                        {dropdownPlaceId === p.id && (
                          <div
                            className="day-dropdown"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {itineraryDays.map((day, idx) => (
                              <div
                                key={idx}
                                className="day-dropdown-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDropdownSelect(p.id, idx);
                                }}
                              >
                                {day.date ? day.date : day}
                              </div>
                            ))}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
              {!collapsed[cat] && items.length === 0 && (
                <div className="no-places-msg">No places added</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TripPlaces;
