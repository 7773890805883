// TripItinerary.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IoTrash, IoDocumentText, IoArrowUp, IoArrowDown, IoStar, IoStarOutline } from 'react-icons/io5';
import { FaHighlighter } from 'react-icons/fa';
import '../../styles/TripDetail/TripItinerary.css';

// Utility function to generate a unique ID for itinerary items
const generateUniqueId = () => {
  return 'itinerary_' + Date.now() + '_' + Math.floor(Math.random() * 10000);
};

/**
 * Reorder an array by removing the item at fromIndex
 * and inserting it at toIndex.
 */
function arrayReorder(arr, fromIndex, toIndex) {
  const newArr = [...arr];
  const [movedItem] = newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, movedItem);
  return newArr;
}

/**
 * Remove an item from one array and insert it into another array at a position.
 */
function moveItemBetweenArrays(sourceArr, targetArr, fromIndex, toIndex) {
  const newSource = [...sourceArr];
  const newTarget = [...targetArr];
  const [movedItem] = newSource.splice(fromIndex, 1);
  newTarget.splice(toIndex, 0, movedItem);
  return { newSource, newTarget };
}

/**
 * Utility to remove any invalid references:
 * - If a place (by its originalId or id) is not found in the global `places` array, remove it.
 */
function sanitizeItinerary(uncheckedItinerary, allPlaces) {
  // DISABLED: Don't remove items from the itinerary based on places array
  // This was causing places to be removed from the itinerary when they should be saved
  return uncheckedItinerary;
  
  /* Original implementation:
  const validIds = new Set(allPlaces.map(pl => pl.id));
  return uncheckedItinerary.map(dayObj => {
    const filtered = (dayObj.places || []).filter(p => {
      const origId = p.originalId || p.id;
      return origId && validIds.has(origId);
    });
    return { ...dayObj, places: filtered };
  });
  */
}

/**
 * Given an array of itinerary items for a day,
 * reorders only the items that have a non-empty time (assumed in "HH:MM" format)
 * while leaving items with blank times in their original positions.
 */
function sortTimedItemsInDay(places) {
  const timedIndices = [];
  const timedItems = [];
  for (let i = 0; i < places.length; i++) {
    if (places[i].time && places[i].time.trim() !== '') {
      timedIndices.push(i);
      timedItems.push(places[i]);
    }
  }
  // Sort timed items by time (converted to minutes)
  timedItems.sort((a, b) => {
    const [aH, aM] = a.time.split(':').map(Number);
    const [bH, bM] = b.time.split(':').map(Number);
    return (aH * 60 + aM) - (bH * 60 + bM);
  });
  // Reinsert the sorted timed items into their original indices
  const newPlaces = [...places];
  for (let j = 0; j < timedIndices.length; j++) {
    newPlaces[timedIndices[j]] = timedItems[j];
  }
  return newPlaces;
}

/**
 * Helper: compare two times in "HH:MM" format.
 * Returns a negative number if t1 < t2, zero if equal, or positive if t1 > t2.
 */
function compareTime(t1, t2) {
  const [h1, m1] = t1.split(':').map(Number);
  const [h2, m2] = t2.split(':').map(Number);
  return (h1 * 60 + m1) - (h2 * 60 + m2);
}

/**
 * Helper: convert any URLs in notes to actual links.
 */
function linkify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, i) => {
    if (urlRegex.test(part)) {
      return (
        <a key={i} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

function TripItinerary({
  itinerary,            // array of { date: string, places: Place[], active?: boolean, highlighted?: boolean }
  onItineraryChange,    // callback(newItinerary)
  places                // all places from Firestore, if needed
}) {
  // Keep a local copy for real-time reordering:
  const [tempItinerary, setTempItinerary] = useState(itinerary);

  // Notes functionality state
  const [activeNotesItineraryId, setActiveNotesItineraryId] = useState(null);
  const [itineraryNotesTemp, setItineraryNotesTemp] = useState("");
  const [isEditingItineraryNotes, setIsEditingItineraryNotes] = useState(false);

  // Update local state when itinerary changes
  useEffect(() => {
    // IMPORTANT: Don't sanitize the itinerary as it was causing places to be removed
    // Just use the itinerary as-is
    console.log("[TripItinerary] Updating temp itinerary, itinerary length:", itinerary.length);
    
    // Count how many places are in the itinerary
    let placeCount = 0;
    itinerary.forEach(day => {
      if (day.places && Array.isArray(day.places)) {
        placeCount += day.places.length;
      }
    });
    console.log("[TripItinerary] Total places in itinerary:", placeCount);
    
    setTempItinerary(itinerary);
  }, [itinerary]);

  // Helper to commit changes:
  const commitItinerary = useCallback(
    (draft) => {
      // IMPORTANT: Don't sanitize the itinerary anymore
      // Just pass the draft directly to avoid removing places
      console.log("[TripItinerary] commitItinerary: Saving itinerary changes");
      
      // Log how many places are in the draft itinerary
      let placeCount = 0;
      draft.forEach(day => {
        if (day.places && Array.isArray(day.places)) {
          placeCount += day.places.length;
          // Log each place in the day for debugging
          day.places.forEach(place => {
            console.log(`[TripItinerary] Place in day ${day.date}: ${place.name}`);
          });
        }
      });
      console.log(`[TripItinerary] Saving itinerary with ${placeCount} total places`);
      
      setTempItinerary(draft);
      onItineraryChange(draft);
    },
    [onItineraryChange]
  );

  // ---------------------------
  // 1) DRAG HANDLERS FOR ITINERARY ITEMS
  // ---------------------------
  // Track what item is being dragged
  const draggingItemRef = useRef({
    dayIndex: null,
    itemIndex: null,
    itineraryId: null,
  });

  // Flag to track a valid drop:
  const didDropRef = useRef(false);

  const handleDragStart = (dayIndex, itemIndex, itineraryId, e) => {
    draggingItemRef.current = { dayIndex, itemIndex, itineraryId };
    didDropRef.current = false;
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnd = () => {
    draggingItemRef.current = { dayIndex: null, itemIndex: null, itineraryId: null };
  };

  // When dragging over an itinerary item:
  const handleDragEnterItem = (targetDayIndex, targetItemIndex) => {
    const { dayIndex: sourceDayIndex, itemIndex: sourceItemIndex, itineraryId } =
      draggingItemRef.current;
    if (itineraryId === null) return;

    const newTemp = [...tempItinerary];
    if (!newTemp[sourceDayIndex] || !newTemp[targetDayIndex]) return;
    const sourcePlaces = [...newTemp[sourceDayIndex].places];
    const targetPlaces =
      targetDayIndex === sourceDayIndex
        ? sourcePlaces
        : [...newTemp[targetDayIndex].places];

    // SAME DAY REORDER:
    if (targetDayIndex === sourceDayIndex) {
      // If both the dragged item and the target item have a set time,
      // ignore the drag (so that dragging a 3pm into a 2pm slot does nothing)
      const sourceItem = sourcePlaces[sourceItemIndex];
      const targetItem = sourcePlaces[targetItemIndex];
      if (
        sourceItem.time &&
        sourceItem.time.trim() !== '' &&
        targetItem &&
        targetItem.time &&
        targetItem.time.trim() !== ''
      ) {
        return;
      }
      if (targetItemIndex !== sourceItemIndex) {
        const reordered = arrayReorder(sourcePlaces, sourceItemIndex, targetItemIndex);
        newTemp[sourceDayIndex].places = reordered;
        setTempItinerary(newTemp);
        draggingItemRef.current.itemIndex = targetItemIndex;
      }
    } else {
      // DIFFERENT DAY: allowed. Reset time on the moved item.
      const { newSource, newTarget } = moveItemBetweenArrays(
        sourcePlaces,
        targetPlaces,
        sourceItemIndex,
        targetItemIndex
      );
      if (newTarget[targetItemIndex]) {
        newTarget[targetItemIndex].time = '';
      }
      newTemp[sourceDayIndex].places = newSource;
      newTemp[targetDayIndex].places = newTarget;
      setTempItinerary(newTemp);
      draggingItemRef.current.dayIndex = targetDayIndex;
      draggingItemRef.current.itemIndex = targetItemIndex;
    }
    didDropRef.current = true;
  };

  // Drop on the day container (footer)
  const handleDropOnDayFooter = (targetDayIndex) => {
    const { dayIndex: sourceDayIndex, itemIndex: sourceItemIndex, itineraryId } =
      draggingItemRef.current;
    if (itineraryId === null) return;

    const newTemp = [...tempItinerary];
    if (!newTemp[sourceDayIndex] || !newTemp[targetDayIndex]) return;
    const sourcePlaces = [...newTemp[sourceDayIndex].places];
    const targetPlaces =
      targetDayIndex === sourceDayIndex
        ? sourcePlaces
        : [...newTemp[targetDayIndex].places];

    // For same-day drop, if the dragged item is timed, ignore the drop
    if (targetDayIndex === sourceDayIndex) {
      if (
        sourcePlaces[sourceItemIndex].time &&
        sourcePlaces[sourceItemIndex].time.trim() !== ''
      ) {
        return;
      }
      const [moved] = sourcePlaces.splice(sourceItemIndex, 1);
      targetPlaces.push(moved);
      newTemp[sourceDayIndex].places = targetPlaces;
      draggingItemRef.current.itemIndex = targetPlaces.length - 1;
    } else {
      const [moved] = sourcePlaces.splice(sourceItemIndex, 1);
      moved.time = ''; // Reset time when moving across days
      targetPlaces.push(moved);
      newTemp[sourceDayIndex].places = sourcePlaces;
      newTemp[targetDayIndex].places = targetPlaces;
      draggingItemRef.current.dayIndex = targetDayIndex;
      draggingItemRef.current.itemIndex = targetPlaces.length - 1;
    }
    setTempItinerary(newTemp);
    didDropRef.current = true;
  };

  const onRemovePlaceFromItinerary = (dayIndex, itineraryItemId) => {
    const newTemp = [...tempItinerary];
    if (!newTemp[dayIndex]) return;
    const oldPlaces = [...newTemp[dayIndex].places];
    newTemp[dayIndex].places = oldPlaces.filter(
      (p) => (p.itineraryId || p.id) !== itineraryItemId
    );
    commitItinerary(newTemp);
  };

  // NEW: Handler to delete an entire day (used for inactive days)
  const handleDeleteDay = (dayIndex) => {
    const newTemp = tempItinerary.filter((_, idx) => idx !== dayIndex);
    commitItinerary(newTemp);
  };

  // NEW: Toggle the highlighted flag for a day
  const toggleDayHighlight = (dayIndex) => {
    const newTemp = [...tempItinerary];
    newTemp[dayIndex] = {
      ...newTemp[dayIndex],
      highlighted: !newTemp[dayIndex].highlighted
    };
    commitItinerary(newTemp);
  };

  // ---------------------------
  // 2) HANDLERS FOR DROPPING FROM THE PLACES LIST
  // ---------------------------
  const handleAddPlaceToDay = (placeId, dayIdx) => {
    const placeObj = places.find((pl) => pl.id === placeId);
    if (!placeObj) return;
    const newTemp = [...tempItinerary];
    if (!newTemp[dayIdx]) return;
    const dayPlaces = [...newTemp[dayIdx].places];
    const newItineraryItem = {
      ...placeObj,
      itineraryId: generateUniqueId(),
      originalId: placeObj.id,
      time: '', // start with no time
      notes: '', // initialize with no notes
    };
    dayPlaces.push(newItineraryItem);
    newTemp[dayIdx].places = dayPlaces;
    commitItinerary(newTemp);
  };

  // ---------------------------
  // 3) TIME INPUT HANDLING & AUTO-SORTING
  // ---------------------------
  const handleTimeChange = (dayIndex, itemIndex, event) => {
    const newTime = event.target.value;
    const newTemp = [...tempItinerary];
    if (!newTemp[dayIndex]) return;
    let dayPlaces = [...newTemp[dayIndex].places];
    const updatedItem = { ...dayPlaces[itemIndex], time: newTime };
    dayPlaces[itemIndex] = updatedItem;
    // Auto-sort only the items with a non-empty time
    if (newTime.trim() !== '') {
      dayPlaces = sortTimedItemsInDay(dayPlaces);
    }
    newTemp[dayIndex].places = dayPlaces;
    setTempItinerary(newTemp);
    // Always commit the itinerary to Firebase, regardless of whether there's a time or not
    commitItinerary(newTemp);
  };

  // ---------------------------
  // 4) NOTES HANDLERS FOR ITINERARY ITEMS
  // ---------------------------
  const toggleItineraryNotes = (item) => {
    const uniqueId = item.itineraryId || item.id;
    if (activeNotesItineraryId === uniqueId) {
      // Close notes if already open
      setActiveNotesItineraryId(null);
      setItineraryNotesTemp("");
      setIsEditingItineraryNotes(false);
    } else {
      setActiveNotesItineraryId(uniqueId);
      setItineraryNotesTemp(item.notes || "");
      setIsEditingItineraryNotes(false);
    }
  };

  const startEditingItineraryNotes = () => {
    setIsEditingItineraryNotes(true);
  };

  const saveItineraryNotes = () => {
    const updatedItinerary = tempItinerary.map((day) => {
      return {
        ...day,
        places: day.places.map((place) => {
          const uniqueId = place.itineraryId || place.id;
          if (uniqueId === activeNotesItineraryId) {
            return { ...place, notes: itineraryNotesTemp };
          }
          return place;
        }),
      };
    });
    commitItinerary(updatedItinerary);
    setIsEditingItineraryNotes(false);
  };

  // ---------------------------
  // 5) ARROW MOVE HANDLERS (UP/DOWN)
  // ---------------------------
  const handleMoveUp = (dayIndex, itemIndex) => {
    // Cannot move up if this is the very first item of the entire itinerary
    if (dayIndex === 0 && itemIndex === 0) return;

    const newTemp = [...tempItinerary];
    const movingItem = { ...newTemp[dayIndex].places[itemIndex] };

    let targetDayIndex, targetItemIndex;
    if (itemIndex > 0) {
      // Within the same day: move one position up.
      targetDayIndex = dayIndex;
      targetItemIndex = itemIndex - 1;
    } else {
      // At the top of the day: move to the previous day (append to its end)
      targetDayIndex = dayIndex - 1;
      targetItemIndex = newTemp[targetDayIndex].places.length;
    }

    // If moving across days, reset time.
    if (targetDayIndex !== dayIndex) {
      movingItem.time = '';
    } else {
      // Same-day move: check for time conflict.
      const targetItem = newTemp[targetDayIndex].places[targetItemIndex];
      if (
        movingItem.time && movingItem.time.trim() !== '' &&
        targetItem && targetItem.time && targetItem.time.trim() !== ''
      ) {
        // For a valid order, the moving item's time should be less than or equal to the target's time.
        if (compareTime(movingItem.time, targetItem.time) > 0) {
          movingItem.time = '';
        }
      }
    }

    // Remove the item from its current location.
    const sourcePlaces = [...newTemp[dayIndex].places];
    sourcePlaces.splice(itemIndex, 1);
    newTemp[dayIndex].places = sourcePlaces;

    // Insert the item into the target location.
    const targetPlaces = [...newTemp[targetDayIndex].places];
    targetPlaces.splice(targetItemIndex, 0, movingItem);
    newTemp[targetDayIndex].places = targetPlaces;

    commitItinerary(newTemp);
  };

  const handleMoveDown = (dayIndex, itemIndex) => {
    const lastDayIndex = tempItinerary.length - 1;
    const currentDayPlaces = tempItinerary[dayIndex].places;
    // Cannot move down if this is the very last item of the itinerary.
    if (dayIndex === lastDayIndex && itemIndex === currentDayPlaces.length - 1) return;

    const newTemp = [...tempItinerary];
    const movingItem = { ...newTemp[dayIndex].places[itemIndex] };

    let targetDayIndex, targetItemIndex;
    if (itemIndex < currentDayPlaces.length - 1) {
      // Within the same day: move one position down.
      targetDayIndex = dayIndex;
      targetItemIndex = itemIndex + 1;
    } else {
      // At the bottom of the day: move to the next day (insert at beginning)
      targetDayIndex = dayIndex + 1;
      targetItemIndex = 0;
    }

    // If moving across days, reset time.
    if (targetDayIndex !== dayIndex) {
      movingItem.time = '';
    } else {
      // Same-day move: check for time conflict.
      const targetItem = newTemp[targetDayIndex].places[targetItemIndex];
      if (
        movingItem.time && movingItem.time.trim() !== '' &&
        targetItem && targetItem.time && targetItem.time.trim() !== ''
      ) {
        // For a valid order when moving down, the moving item's time should be greater than or equal to the target's time.
        if (compareTime(movingItem.time, targetItem.time) < 0) {
          movingItem.time = '';
        }
      }
    }

    // Remove the item from its current location.
    const sourcePlaces = [...newTemp[dayIndex].places];
    sourcePlaces.splice(itemIndex, 1);
    newTemp[dayIndex].places = sourcePlaces;

    // Insert the item into the target location.
    const targetPlaces = [...newTemp[targetDayIndex].places];
    targetPlaces.splice(targetItemIndex, 0, movingItem);
    newTemp[targetDayIndex].places = targetPlaces;

    commitItinerary(newTemp);
  };

  return (
    <div className="itinerary-container">
      <h3>Itinerary</h3>
      {tempItinerary.length === 0 ? (
        <div>No days available.</div>
      ) : (
        <div className="itinerary-day-list">
          {tempItinerary.map((day, dayIdx) => (
            <div
              key={day.date}
              className={`itinerary-day-section ${day.active === false ? 'inactive-day' : ''}`}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // Extended drop zone check for 50% overlap
                const rect = e.currentTarget.getBoundingClientRect();
                const extendedRect = {
                  top: rect.top - rect.height / 2,
                  bottom: rect.bottom + rect.height / 2,
                  left: rect.left - rect.width / 2,
                  right: rect.right + rect.width / 2,
                };
                if (
                  e.clientX < extendedRect.left ||
                  e.clientX > extendedRect.right ||
                  e.clientY < extendedRect.top ||
                  e.clientY > extendedRect.bottom
                ) {
                  return;
                }
                // Check if drop is coming from the places list:
                const dataJson = e.dataTransfer.getData('application/json');
                if (dataJson) {
                  try {
                    const parsed = JSON.parse(dataJson);
                    if (parsed.source === 'placeList' && parsed.placeId) {
                      handleAddPlaceToDay(parsed.placeId, dayIdx);
                      didDropRef.current = true;
                      return;
                    }
                  } catch (err) {
                    // ignore parsing errors
                  }
                }
                if (e.target === e.currentTarget) {
                  handleDropOnDayFooter(dayIdx);
                }
              }}
            >
              <div className="itinerary-day-header">
                <span>{day.date}</span>
                {/* NEW: Highlight toggle button */}
                <button
                  className="itinerary-day-highlight icon-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDayHighlight(dayIdx);
                  }}
                >
                  <FaHighlighter className={day.highlighted ? "highlighted" : "not-highlighted"} />
                </button>
                {/* If day is inactive, show a delete icon for the whole day */}
                {day.active === false && (
                  <button
                    className="itinerary-day-delete icon-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteDay(dayIdx);
                    }}
                  >
                    <IoTrash />
                  </button>
                )}
              </div>
              {(!day.places || day.places.length === 0) ? (
                <div className="no-places-msg">No places assigned</div>
              ) : (
                <ul className="itinerary-day-places">
                  {day.places.map((p, itemIdx) => {
                    const uniqueId = p.itineraryId || p.id;
                    const showNotes = activeNotesItineraryId === uniqueId;
                    // Determine if move arrows should be enabled.
                    const canMoveUp = !(dayIdx === 0 && itemIdx === 0);
                    const canMoveDown =
                      !(dayIdx === tempItinerary.length - 1 &&
                        itemIdx === day.places.length - 1);
                    return (
                      <li
                        key={uniqueId}
                        className="itinerary-place-item"
                        draggable
                        onDragStart={(e) => handleDragStart(dayIdx, itemIdx, uniqueId, e)}
                        onDragEnd={handleDragEnd}
                        onDragEnter={() => handleDragEnterItem(dayIdx, itemIdx)}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          didDropRef.current = true;
                        }}
                      >
                        {/* Top row: time, name + notes icon, move arrows, remove button */}
                        <div className="itinerary-item-top-row">
                          <div className="time-input-wrapper">
                            <input
                              type="time"
                              className="time-input"
                              value={p.time || ''}
                              onChange={(e) => handleTimeChange(dayIdx, itemIdx, e)}
                            />
                          </div>

                          <div className="itinerary-place-info">
                            <span className="itinerary-place-name">{p.name}</span>
                            <button
                              className={`itinerary-place-notes-btn icon-btn notes-icon ${
                                p.notes && p.notes.trim() !== "" ? 'has-notes' : 'no-notes'
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleItineraryNotes(p);
                              }}
                            >
                              <IoDocumentText />
                            </button>
                          </div>

                          <button
                            className="itinerary-move-up icon-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMoveUp(dayIdx, itemIdx);
                            }}
                            disabled={!canMoveUp}
                          >
                            <IoArrowUp />
                          </button>
                          <button
                            className="itinerary-move-down icon-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMoveDown(dayIdx, itemIdx);
                            }}
                            disabled={!canMoveDown}
                          >
                            <IoArrowDown />
                          </button>

                          <button
                            className="itinerary-place-remove icon-btn"
                            onClick={() => onRemovePlaceFromItinerary(dayIdx, uniqueId)}
                          >
                            <IoTrash />
                          </button>
                        </div>

                        {/* Notes content, shown below */}
                        {showNotes && (
                          <div
                            className="inline-notes"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {isEditingItineraryNotes ? (
                              <>
                                <textarea
                                  className="notes-textarea"
                                  value={itineraryNotesTemp}
                                  onChange={(e) => setItineraryNotesTemp(e.target.value)}
                                />
                                <button
                                  className="save-notes-btn"
                                  onClick={saveItineraryNotes}
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <div
                                className="notes-content"
                                onClick={startEditingItineraryNotes}
                              >
                                {itineraryNotesTemp.trim().length > 0
                                  ? linkify(itineraryNotesTemp)
                                  : 'No notes yet. Click to edit.'}
                              </div>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TripItinerary;
