// TripMap.js
import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import '../../styles/TripDetail/TripMap.css'; // Import the map-specific CSS

function TripMap({
  center,
  places,
  selectedPlaceId,
  onMarkerClick,
  handleMapClick,
  getMarkerIcon,
  mapRef,
}) {
  // New handler for double-clicking on a marker:
  const handleMarkerDblClick = (p) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat: p.lat, lng: p.lng });
    }
  };

  return (
    <GoogleMap
      onLoad={(map) => (mapRef.current = map)}
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={center}
      zoom={12}
      onClick={handleMapClick}
      options={{ mapTypeControl: false, clickableIcons: false }}
    >
      {places.map((p) => (
        <Marker
          key={p.id}
          position={{ lat: p.lat, lng: p.lng }}
          onClick={() => onMarkerClick(p)}
          onDblClick={() => handleMarkerDblClick(p)}
          icon={getMarkerIcon(p.id)}
          zIndex={selectedPlaceId === p.id ? 1000 : 1} // Higher zIndex for selected (blue) marker
        />
      ))}
    </GoogleMap>
  );
}

export default TripMap;
