// src/components/TripDetail/TripDetail.js
import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback
} from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  query,
  orderBy,
  serverTimestamp,
  setDoc
} from 'firebase/firestore';
import { db } from '../../firebase';
import { updateCommunityRecommendation, removePlaceRecommendation } from '../../suggestionsService';

import TripMap from './TripMap';
import TripPlaces from './TripPlaces';
import TripItinerary from './TripItinerary';
import TripDetails from './TripDetails';

import SuggestionModal from './SuggestionModal';
import { IoMenu } from 'react-icons/io5';

import '../../styles/TripDetail/TripDetail.css';

// --- Helper: determines place category based on place.types
function getCategoryFromTypes(types) {
  if (!types) return 'Others';
  const lowerTypes = types.map(t => t.toLowerCase());

  if (lowerTypes.some(t => ['lodging', 'hotel'].includes(t))) {
    return 'Accommodations';
  }
  if (lowerTypes.some(t => ['restaurant', 'bar', 'cafe', 'food'].includes(t))) {
    return 'Food';
  }
  if (lowerTypes.some(t =>
    [
      'tourist_attraction',
      'museum',
      'park',
      'zoo',
      'art_gallery',
      'point_of_interest'
    ].includes(t)
  )) {
    return 'Attractions';
  }
  return 'Others';
}

function TripDetail({ trip, selectedPlaceId, onSelectPlace, isMobile, onMenuClick }) {
  // Destructure trip properties
  const { id: tripId, lat, lng, startDate, endDate, location, sections } = trip;
  const [activeSection, setActiveSection] = useState(null);

  // -------------------------------
  // 1) ITINERARY STATE & LOADING
  // -------------------------------
  const [itinerary, setItinerary] = useState([]);
  useEffect(() => {
    setItinerary([]);
  }, [tripId, activeSection]);
  // We've replaced this with the more comprehensive loadAndFilterItinerary in the effect below

  // -------------------------------
  // 2) PLACES STATE & LOADING
  // -------------------------------
  const [places, setPlaces] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [collapsed, setCollapsed] = useState({
    Attractions: false,
    Food: false,
    Accommodations: false,
    Others: false
  });
  const [notesPlaceId, setNotesPlaceId] = useState(null);
  const [tempNotes, setTempNotes] = useState('');
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [activeContentSections, setActiveContentSections] = useState(['brainstorm']); // Array of active sections

  // Google Maps / Autocomplete
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  
  // Get the active location and coordinates
  const activeSectionData = useMemo(() => {
    if (!activeSection || !sections) return { 
      activeLocation: location, 
      activeLat: lat, 
      activeLng: lng,
      activeStartDate: startDate,
      activeEndDate: endDate
    };
    
    const sectionObj = sections.find(s => `section-${s.location}` === activeSection);
    if (sectionObj) {
      return {
        activeLocation: sectionObj.location,
        activeLat: sectionObj.lat || lat,
        activeLng: sectionObj.lng || lng,
        activeStartDate: sectionObj.startDate || startDate,
        activeEndDate: sectionObj.endDate || endDate
      };
    }
    
    return { 
      activeLocation: location, 
      activeLat: lat, 
      activeLng: lng,
      activeStartDate: startDate,
      activeEndDate: endDate
    };
  }, [activeSection, sections, location, lat, lng, startDate, endDate]);

  // Get the active center coordinates
  const activeCenter = useMemo(() => {
    return { 
      lat: activeSectionData.activeLat, 
      lng: activeSectionData.activeLng 
    };
  }, [activeSectionData]);
  
  const center = useMemo(() => activeCenter, [activeCenter]);
  const bounds = useMemo(() => {
    const delta = 0.1;
    return new window.google.maps.LatLngBounds(
      { lat: activeSectionData.activeLat - delta, lng: activeSectionData.activeLng - delta },
      { lat: activeSectionData.activeLat + delta, lng: activeSectionData.activeLng + delta }
    );
  }, [activeSectionData.activeLat, activeSectionData.activeLng]);

  const [mapHeight, setMapHeight] = useState(window.innerHeight * 0.5);
  const isResizingRef = useRef(false);
  useEffect(() => {
    const colRef = collection(db, 'trips', tripId, 'places');
    const qPlaces = query(colRef, orderBy('category'));
    const unsub = onSnapshot(qPlaces, snapshot => {
      const placeData = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
      setPlaces(placeData);
    });
    return () => unsub();
  }, [tripId]);

  // Prune itinerary using a functional update (removing itinerary from dependency)
  useEffect(() => {
    setItinerary(prevItinerary => {
      if (places.length === 0) {
        if (prevItinerary.some(day => (day.places && day.places.length > 0))) {
          const cleared = prevItinerary.map(day => ({ ...day, places: [] }));
          if (tripId) {
            updateDoc(doc(db, 'trips', tripId), { itinerary: cleared });
          }
          return cleared;
        }
        return prevItinerary;
      }
      const validIds = new Set(places.map(p => p.id));
      let changed = false;
      const newItinerary = prevItinerary.map(dayObj => {
        const filteredPlaces = (dayObj.places || []).filter(p => {
          const origId = p.originalId || p.id;
          return validIds.has(origId);
        });
        if (filteredPlaces.length !== (dayObj.places || []).length) {
          changed = true;
        }
        return { ...dayObj, places: filteredPlaces };
      });
      if (changed) {
        if (tripId) {
          updateDoc(doc(db, 'trips', tripId), { itinerary: newItinerary });
        }
        return newItinerary;
      }
      return prevItinerary;
    });
  }, [places, tripId]);

  // -------------------------------
  // 3) CREATE OR UPDATE ITINERARY DAYS
  // -------------------------------
  function getTripDays(start, end) {
    if (!start || !end) return [];
    const startObj = new Date(start);
    const endObj = new Date(end);
    if (isNaN(startObj) || isNaN(endObj) || startObj > endObj) return [];
    const days = [];
    let current = new Date(startObj);
    while (current <= endObj) {
      days.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return days;
  }
  // Handle itinerary days based on active section dates
  useEffect(() => {
    if (!tripId) return;
    
    const loadAndFilterItinerary = async () => {
      // First, always try to load full itinerary from Firebase
      console.log(`[loadAndFilterItinerary] Loading itinerary for trip: ${tripId}`);
      const tripRef = doc(db, 'trips', tripId);
      const snapshot = await getDoc(tripRef);
      
      let fullItinerary = [];
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data.itinerary && Array.isArray(data.itinerary)) {
          fullItinerary = data.itinerary;
          console.log(`[loadAndFilterItinerary] Loaded ${fullItinerary.length} days from full itinerary`);
        }
      }
      
      // Use the active section's dates for filtering
      const effectiveStartDate = activeSectionData.activeStartDate;
      const effectiveEndDate = activeSectionData.activeEndDate;
      
      if (!effectiveStartDate || !effectiveEndDate) {
        console.log(`[loadAndFilterItinerary] No effective dates found, skipping`);
        return;
      }
      
      const newDates = getTripDays(effectiveStartDate, effectiveEndDate).map(d =>
        d.toISOString().split('T')[0]
      );
      
      // If no itinerary exists yet in Firebase, create a fresh one
      if (fullItinerary.length === 0) {
        console.log(`[loadAndFilterItinerary] Creating fresh itinerary for dates:`, newDates);
        const freshItinerary = newDates.map(date => ({
          date,
          places: [],
          active: true,
          highlighted: false
        }));
        
        setItinerary(freshItinerary);
        // Save the fresh itinerary to Firebase
        try {
          await updateDoc(tripRef, { itinerary: freshItinerary });
          console.log(`[loadAndFilterItinerary] Saved fresh itinerary to Firebase`);
        } catch (error) {
          console.error(`[loadAndFilterItinerary] Error saving fresh itinerary:`, error);
        }
        return;
      }
      
      // Create a map of existing days in the itinerary
      const existingDatesMap = {};
      fullItinerary.forEach(day => {
        existingDatesMap[day.date] = day;
      });
      
      // If we're viewing a section, filter to only show relevant dates
      // but PRESERVE all other dates and their places in the stored data
      let displayItinerary;
      
      if (activeSection) {
        console.log(`[loadAndFilterItinerary] Filtering itinerary display for section dates`);
        
        // Build an updated itinerary that preserves all data but marks dates as active/inactive
        // First, start with a copy of the full itinerary
        const updatedFullItinerary = [...fullItinerary];
        
        // Set all days to inactive by default
        updatedFullItinerary.forEach(day => {
          day.active = false;
        });
        
        // For each date in the current section, either create a new day or update an existing one
        newDates.forEach(date => {
          const existingDayIndex = updatedFullItinerary.findIndex(day => day.date === date);
          
          if (existingDayIndex >= 0) {
            // Update existing day to be active
            updatedFullItinerary[existingDayIndex].active = true;
          } else {
            // Add new day for this date
            updatedFullItinerary.push({
              date,
              places: [],
              active: true,
              highlighted: false
            });
          }
        });
        
        // Save the complete updated itinerary back to Firebase to preserve all days
        try {
          await updateDoc(tripRef, { itinerary: updatedFullItinerary });
          console.log(`[loadAndFilterItinerary] Saved complete updated itinerary to Firebase: ${updatedFullItinerary.length} days`);
        } catch (error) {
          console.error(`[loadAndFilterItinerary] Error saving complete itinerary:`, error);
        }
        
        // For display, filter to only show active dates
        displayItinerary = updatedFullItinerary.filter(day => day.active);
        console.log(`[loadAndFilterItinerary] Filtered to ${displayItinerary.length} active days for current section`);
      } else {
        // For main trip view, show all dates and mark them all as active
        console.log(`[loadAndFilterItinerary] Using full itinerary for main view`);
        displayItinerary = fullItinerary.map(day => ({
          ...day,
          active: true
        }));
        
        // Save the updated itinerary with all days active
        try {
          await updateDoc(tripRef, { itinerary: displayItinerary });
          console.log(`[loadAndFilterItinerary] Saved full active itinerary to Firebase`);
        } catch (error) {
          console.error(`[loadAndFilterItinerary] Error saving full active itinerary:`, error);
        }
      }
      
      // Sort the display itinerary by date
      displayItinerary.sort((a, b) => new Date(a.date) - new Date(b.date));
      
      console.log(`[loadAndFilterItinerary] Setting display itinerary with ${displayItinerary.length} days`);
      
      // Count total places in the itinerary and log details about each place
      let placeCount = 0;
      displayItinerary.forEach(day => {
        if (day.places && Array.isArray(day.places)) {
          placeCount += day.places.length;
          // Log each place in the day for debugging
          day.places.forEach(place => {
            console.log(`[loadAndFilterItinerary] Place in day ${day.date}: ${place.name}, time: "${place.time || ''}"`);
          });
        }
      });
      console.log(`[loadAndFilterItinerary] Total places in display itinerary: ${placeCount}`);
      
      // Ensure that each day has its places array initialized
      const validatedItinerary = displayItinerary.map(day => ({
        ...day,
        places: Array.isArray(day.places) ? day.places : []
      }));
      
      setItinerary(validatedItinerary);
    };
    
    loadAndFilterItinerary();
  }, [tripId, activeSectionData.activeStartDate, activeSectionData.activeEndDate, activeSection]);

  // -------------------------------
  // 4) MAP & PLACES INTERACTIONS
  // -------------------------------
  const onPlaceChanged = async () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry && place.geometry.location) {
        const placeLat = place.geometry.location.lat();
        const placeLng = place.geometry.location.lng();
        const category = getCategoryFromTypes(place.types || []);
        const currentCatPlaces = places.filter(p => p.category === category);
        const order = currentCatPlaces.length;
        
        // Create place object
        const placeObj = {
          name: place.name || place.formatted_address,
          lat: placeLat,
          lng: placeLng,
          category,
          order,
          description: '', // Leave description blank since there is no AI description
          sectionId: activeSection // Add the section ID if we're in a section
        };
        
        // Add to the trip's places collection
        await addDoc(collection(db, 'trips', tripId, 'places'), placeObj);
        
        // Get user ID from trip document
        const tripRef = doc(db, 'trips', tripId);
        const tripDoc = await getDoc(tripRef);
        let userId = null;
        
        if (tripDoc.exists()) {
          const tripData = tripDoc.data();
          userId = tripData.userId;
        }
        
        if (!userId) {
          // Fallback to stored user ID if available
          const storedUserId = localStorage.getItem('userId');
          if (storedUserId) {
            userId = storedUserId;
          } else {
            // Last resort is to use a derived ID
            userId = "user-" + tripId;
          }
        }
        
        // Update community recommendations with proper user ID
        await updateCommunityRecommendation(userId, placeObj, category, activeSectionData.activeLocation);
        
        setInputValue('');
      }
    }
  };
  const handleDeletePlace = async placeId => {
    try {
      // Get the place details before deleting
      const placeRef = doc(db, 'trips', tripId, 'places', placeId);
      const placeDoc = await getDoc(placeRef);
      
      if (placeDoc.exists()) {
        const placeData = placeDoc.data();
        
        // Delete the place from trips collection
        await deleteDoc(placeRef);
        
        // Get user ID from trip document
        const tripRef = doc(db, 'trips', tripId);
        const tripDoc = await getDoc(tripRef);
        let userId = null;
        
        if (tripDoc.exists()) {
          const tripData = tripDoc.data();
          userId = tripData.userId;
        }
        
        if (!userId) {
          // Fallback to stored user ID if available
          const storedUserId = localStorage.getItem('userId');
          if (storedUserId) {
            userId = storedUserId;
          } else {
            // Last resort is to use a derived ID
            userId = "user-" + tripId;
          }
        }
        
        // Also remove from community recommendations
        await removePlaceRecommendation(
          userId, 
          placeData.name, 
          placeData.category, 
          placeData.sectionId ? activeSectionData.activeLocation : location
        );
        
        console.log(`[handleDeletePlace] Removed place "${placeData.name}" and updated community recommendations`);
      }
      
      // Update UI
      if (selectedPlaceId === placeId) {
        onSelectPlace(null);
      }
    } catch (error) {
      console.error("[handleDeletePlace] Error deleting place:", error);
    }
  };
  const handleCategoryClick = cat => {
    setCollapsed(prev => ({ ...prev, [cat]: !prev[cat] }));
  };
  const categoryPlaces = useMemo(() => {
    const catMap = {
      Attractions: [],
      Food: [],
      Accommodations: [],
      Others: []
    };
    
    places.forEach(p => {
      // Only include places from the active section, or main trip if no section is active
      if ((activeSection && p.sectionId === activeSection) || 
          (!activeSection && !p.sectionId)) {
        const cat = p.category || 'Others';
        if (!catMap[cat]) {
          catMap[cat] = [];
        }
        catMap[cat].push(p);
      }
    });
    
    return catMap;
  }, [places, activeSection]);
  useEffect(() => {
    if (selectedPlaceId) {
      const el = document.querySelector(`li[data-id="${selectedPlaceId}"]`);
      if (el && el.scrollIntoView) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedPlaceId]);
  const handleMapClick = () => {
    onSelectPlace(null);
  };
  const onMarkerClick = place => {
    onSelectPlace(place.id);
  };
  const toggleMustGo = async place => {
    const placeRef = doc(db, 'trips', tripId, 'places', place.id);
    await updateDoc(placeRef, { mustGo: !place.mustGo });
  };
  const isMustGo = place => place.mustGo === true;
  const hasNotes = place => place.notes && place.notes.trim().length > 0;
  const toggleNotes = place => {
    if (notesPlaceId === place.id) {
      if (!isEditingNotes) {
        setNotesPlaceId(null);
      }
    } else {
      setNotesPlaceId(place.id);
      setTempNotes(place.notes || '');
      setIsEditingNotes(false);
    }
  };
  const saveNotes = async () => {
    if (!notesPlaceId) return;
    const placeRef = doc(db, 'trips', tripId, 'places', notesPlaceId);
    await updateDoc(placeRef, { notes: tempNotes });
    setIsEditingNotes(false);
  };
  const startEditingNotes = () => {
    setIsEditingNotes(true);
  };
  const isInItinerary = placeId => {
    return itinerary.some(dayObj =>
      dayObj.places && dayObj.places.some(p => (p.originalId || p.id) === placeId)
    );
  };
  const onItineraryChange = async newItinerary => {
    // Check if any places have been removed from the itinerary
    const oldPlaces = [];
    const newPlaces = [];
    
    // Get all place IDs from the old itinerary
    itinerary.forEach(day => {
      if (day.places) {
        day.places.forEach(place => {
          oldPlaces.push({
            id: place.itineraryId || place.id,
            originalId: place.originalId || place.id,
            name: place.name,
            category: place.category,
            time: place.time || '' // Include time information
          });
        });
      }
    });
    
    // Get all place IDs from the new itinerary
    newItinerary.forEach(day => {
      if (day.places) {
        day.places.forEach(place => {
          newPlaces.push({
            id: place.itineraryId || place.id,
            originalId: place.originalId || place.id,
            name: place.name,
            category: place.category,
            time: place.time || '' // Include time information
          });
        });
      }
    });
    
    // Find places that have been removed
    const removedPlaces = oldPlaces.filter(oldPlace => 
      !newPlaces.some(newPlace => newPlace.id === oldPlace.id)
    );
    
    // Debug removed places
    if (removedPlaces.length > 0) {
      console.log(`[onItineraryChange] Detected ${removedPlaces.length} removed places`);
      removedPlaces.forEach(place => {
        console.log(`[onItineraryChange] Removed place: ${place.name}, time: "${place.time}"`);
      });
    }
    
    // Log the change for debugging
    console.log(`[onItineraryChange] Updating itinerary with ${newPlaces.length} places`);
    newPlaces.forEach(place => {
      console.log(`[onItineraryChange] Place in updated itinerary: ${place.name}, time: "${place.time}"`);
    });
    
    // Ensure that each day has its places array initialized
    const validatedItinerary = newItinerary.map(day => ({
      ...day,
      places: Array.isArray(day.places) ? day.places : []
    }));
    
    // Update the new itinerary in Firestore
    setItinerary(validatedItinerary);
    if (tripId) {
      try {
        console.log(`[onItineraryChange] Saving itinerary with ${validatedItinerary.length} days to Firestore`);
        
        // Enhanced logging for debugging
        let totalPlaces = 0;
        validatedItinerary.forEach(day => {
          const placesInDay = day.places ? day.places.length : 0;
          console.log(`[onItineraryChange] Day ${day.date} has ${placesInDay} places`);
          totalPlaces += placesInDay;
        });
        console.log(`[onItineraryChange] Total places being saved: ${totalPlaces}`);
        
        // Save to Firebase
        await updateDoc(doc(db, 'trips', tripId), { itinerary: validatedItinerary });
        console.log(`[onItineraryChange] Successfully saved itinerary to Firestore`);
      } catch (error) {
        console.error(`[onItineraryChange] Error saving itinerary to Firestore:`, error);
      }
      
      // Remove removed places from community recommendations
      if (removedPlaces.length > 0) {
        // Get user ID from trip document
        const tripRef = doc(db, 'trips', tripId);
        const tripDoc = await getDoc(tripRef);
        let userId = null;
        
        if (tripDoc.exists()) {
          const tripData = tripDoc.data();
          userId = tripData.userId;
        }
        
        if (!userId) {
          // Fallback to stored user ID if available
          const storedUserId = localStorage.getItem('userId');
          if (storedUserId) {
            userId = storedUserId;
          } else {
            // Last resort is to use a derived ID
            userId = "user-" + tripId;
          }
        }
        
        // Process each removed place
        for (const place of removedPlaces) {
          const originalPlace = places.find(p => p.id === place.originalId);
          if (originalPlace) {
            await removePlaceRecommendation(
              userId,
              originalPlace.name,
              originalPlace.category,
              location
            );
            console.log(`[onItineraryChange] Removed ${originalPlace.name} from community recommendations`);
          }
        }
      }
    }
  };
  // Track place usage across users when adding to itinerary
  const trackPlaceUsage = async (placeObj) => {
    if (!placeObj || !placeObj.name) return;
    
    try {
      // Get current user ID from trip's userId property or from localStorage
      const tripRef = doc(db, 'trips', tripId);
      const tripDoc = await getDoc(tripRef);
      let userId = null;
      
      if (tripDoc.exists()) {
        const tripData = tripDoc.data();
        userId = tripData.userId;
      }
      
      if (!userId) {
        // Fallback to stored user ID if available
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
          userId = storedUserId;
        } else {
          // Last resort is to use a derived ID
          userId = "user-" + tripId;
        }
      }
      
      // Use the updateCommunityRecommendation function with the user ID
      const effectiveLocation = placeObj.sectionId ? activeSectionData.activeLocation : location;
      await updateCommunityRecommendation(userId, placeObj, placeObj.category, effectiveLocation);
      console.log(`[trackPlaceUsage] Updated community count for ${placeObj.name} in ${effectiveLocation} for user ${userId}`);
    } catch (error) {
      console.error("[trackPlaceUsage] Error tracking place usage:", error);
    }
  };

  const handleAddPlaceToItinerary = async (placeId, dayIndex) => {
    const placeObj = places.find(p => p.id === placeId);
    if (!placeObj) return;
    const newItinerary = [...itinerary];
    if (!newItinerary[dayIndex]) return;
    
    // Include section ID to track which section this place is from
    const newItineraryItem = {
      ...placeObj,
      itineraryId: 'itinerary_' + Date.now() + '_' + Math.floor(Math.random() * 10000),
      originalId: placeObj.id,
      time: '',
      notes: '',
      sectionId: activeSection || null // Track which section this place came from
    };
    
    newItinerary[dayIndex] = {
      ...newItinerary[dayIndex],
      places: [...newItinerary[dayIndex].places, newItineraryItem]
    };

    // Track this place in community recommendations
    await trackPlaceUsage(placeObj);

    // Log the change for debugging
    console.log(`[handleAddPlaceToItinerary] Adding ${placeObj.name} to day ${newItinerary[dayIndex].date}`);
    
    // Update state and persist to Firebase
    setItinerary(newItinerary);
    if (tripId) {
      try {
        await updateDoc(doc(db, 'trips', tripId), { itinerary: newItinerary });
        console.log(`[handleAddPlaceToItinerary] Successfully saved to Firestore`);
      } catch (error) {
        console.error(`[handleAddPlaceToItinerary] Error saving to Firestore:`, error);
      }
    }
  };
  const handleSwitchSection = async (section) => {
    // Get current window width to determine if mobile
    const isMobileView = window.innerWidth <= 768;
    
    // Save current itinerary state to Firebase before switching sections
    if (tripId && itinerary.length > 0) {
      console.log(`[handleSwitchSection] Saving current itinerary before switching tab`);
      try {
        await updateDoc(doc(db, 'trips', tripId), { itinerary });
        console.log(`[handleSwitchSection] Successfully saved current itinerary before switching tab`);
      } catch (error) {
        console.error(`[handleSwitchSection] Error saving itinerary before switching tab:`, error);
      }
    }
    
    // Get the current active sections
    let newSections = [...activeContentSections];
    
    // If section is already selected, remove it (toggle off)
    if (newSections.includes(section)) {
      // Don't allow deselecting all sections - keep at least one
      if (newSections.length > 1) {
        newSections = newSections.filter(s => s !== section);
      }
    } else {
      // On mobile, replace the current section
      if (isMobileView) {
        newSections = [section];
      } else {
        // On desktop, allow up to 2 sections
        if (newSections.length >= 2) {
          // Remove the oldest selection
          newSections.shift();
        }
        // Add the new section
        newSections.push(section);
      }
    }
    
    // If itinerary view is selected, ensure it's loaded and up to date
    if (newSections.includes('itinerary')) {
      console.log(`[handleSwitchSection] Itinerary tab selected, refreshing itinerary data`);
      
      // We'll let the effect hook handle loading and filtering the itinerary data
      // based on the current active section and its dates
    }
    
    setActiveContentSections(newSections);
  };
  const highlightedPlaceIds = useMemo(() => {
    const setIds = new Set();
    itinerary.forEach(day => {
      if (day.highlighted) {
        (day.places || []).forEach(p => setIds.add(p.originalId || p.id));
      }
    });
    return setIds;
  }, [itinerary]);
  const getMarkerIcon = placeId => {
    if (selectedPlaceId === placeId) {
      return 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    }
    if (highlightedPlaceIds.has(placeId)) {
      return 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
    }
    return 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  };

  // -------------------------------
  // 5) INSPIRATION (AI Suggestions)
  // -------------------------------
  const [suggestionsData, setSuggestionsData] = useState(null);
  const [suggestionError, setSuggestionError] = useState(null);
  const [inspirationCategory, setInspirationCategory] = useState(null);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [generatedDate, setGeneratedDate] = useState(null);
  const existingPlaceNamesForInspiration = useMemo(() => {
    if (!inspirationCategory) return [];
    return (categoryPlaces[inspirationCategory] || []).map(p => p.name);
  }, [categoryPlaces, inspirationCategory]);

  // This callback is passed to TripPlaces to handle inspiration clicks.
  const handleInspirationClick = useCallback((category, suggestions, error, loading, generatedDate) => {
    console.log('Inspiration clicked for category:', category, suggestions, error, loading, generatedDate);
    setInspirationCategory(category);
    setSuggestionsData(suggestions);
    setSuggestionError(error);
    setLoadingSuggestions(loading);
    setGeneratedDate(generatedDate);
    setShowSuggestionModal(true);
  }, []);

  const handleSelectSuggestion = async suggestion => {
    const existingNames = existingPlaceNamesForInspiration;
    if (existingNames.includes(suggestion.name)) {
      setShowSuggestionModal(false);
      return;
    }
    
    const order = categoryPlaces[inspirationCategory]
      ? categoryPlaces[inspirationCategory].length
      : 0;
    
    // If suggestion already has coordinates (from community recommendations), use them
    let coordinates = {};
    
    if (suggestion.lat && suggestion.lng) {
      coordinates = {
        lat: suggestion.lat,
        lng: suggestion.lng
      };
    } else {
      // Otherwise, geocode the place name
      const geocodePlaceWithFallback = address => {
        return new Promise((resolve, reject) => {
          const geocoder = new window.google.maps.Geocoder();
          const tryGeocode = (addr, attempt = 0) => {
            geocoder.geocode({ address: addr }, (results, status) => {
              if (status === 'OK' && results && results.length > 0) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                resolve({ lat, lng });
              } else {
                if (status === 'ZERO_RESULTS' && attempt === 0 && location) {
                  tryGeocode(`${address}, ${location}`, attempt + 1);
                } else {
                  reject(new Error('Geocode was not successful: ' + status));
                }
              }
            });
          };
          tryGeocode(address);
        });
      };
      
      try {
        coordinates = await geocodePlaceWithFallback(suggestion.name);
      } catch (error) {
        console.error('Error geocoding the suggestion:', error);
        setShowSuggestionModal(false);
        return;
      }
    }
    
    try {
      // Use the category from the suggestion if available, otherwise use the current inspiration category
      const category = suggestion.category || inspirationCategory;
      
      // Create the place object
      const placeObj = {
        name: suggestion.name,
        description: suggestion.description || '',
        imageUrl: suggestion.imageUrl || '',
        category: category,
        order,
        ...coordinates,
        sectionId: activeSection // Add section ID if we're in a section
      };
      
      // Add to places collection
      await addDoc(collection(db, 'trips', tripId, 'places'), placeObj);
      
      // Get user ID from trip document
      const tripRef = doc(db, 'trips', tripId);
      const tripDoc = await getDoc(tripRef);
      let userId = null;
      
      if (tripDoc.exists()) {
        const tripData = tripDoc.data();
        userId = tripData.userId;
      }
      
      if (!userId) {
        // Fallback to stored user ID if available
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
          userId = storedUserId;
        } else {
          // Last resort is to use a derived ID
          userId = "user-" + tripId;
        }
      }
      
      // Track this place in community recommendations using the proper user ID
      await updateCommunityRecommendation(userId, placeObj, category, activeSection ? activeSectionData.activeLocation : location);
      
    } catch (error) {
      console.error('Error adding suggestion to places:', error);
    }
    
    setShowSuggestionModal(false);
  };

  const handlePlaceDoubleClick = place => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat: place.lat, lng: place.lng });
    }
  };

  // -------------------------------
  // 6) RESIZING & RENDERING
  // -------------------------------
  const placesContainerHeight = `calc(100vh - ${mapHeight}px)`;
  const startResizing = useCallback(e => {
    isResizingRef.current = true;
    e.preventDefault();
  }, []);
  const stopResizing = useCallback(() => {
    isResizingRef.current = false;
  }, []);
  const resize = useCallback(e => {
    if (!isResizingRef.current) return;
    const newHeight = e.clientY;
    const minHeight = 100;
    const maxHeight = window.innerHeight - 100;
    if (newHeight > minHeight && newHeight < maxHeight) {
      setMapHeight(newHeight);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);
  
  // Track window resize and enforce mobile restrictions
  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 768;
      
      // If on mobile and multiple sections are active, keep only the most recent one
      if (isMobileView && activeContentSections.length > 1) {
        setActiveContentSections([activeContentSections[activeContentSections.length - 1]]);
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeContentSections]);

  // Handle section change
  const handleSectionChange = async (sectionId) => {
    // Determine the new active section
    const newActiveSection = sectionId === activeSection ? null : sectionId;
    
    // Save the current itinerary before changing sections, marking which days belong to which section
    if (tripId && itinerary.length > 0) {
      console.log(`[handleSectionChange] Saving current itinerary before switching sections`);
      
      // Load the full itinerary first to make sure we have all days from all sections
      const tripRef = doc(db, 'trips', tripId);
      const snapshot = await getDoc(tripRef);
      
      let fullItinerary = [];
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data.itinerary && Array.isArray(data.itinerary)) {
          fullItinerary = data.itinerary;
        }
      }
      
      // Create a map of the current itinerary days being viewed
      const currentItineraryMap = {};
      itinerary.forEach(day => {
        currentItineraryMap[day.date] = day;
      });
      
      // Update the full itinerary with the current itinerary data
      // This preserves places in other sections while updating the current section
      const updatedFullItinerary = fullItinerary.map(day => {
        if (currentItineraryMap[day.date]) {
          // If this day exists in current view, update it with current data
          return currentItineraryMap[day.date];
        }
        return day;
      });
      
      // Also add any new days from the current view that don't exist in the full itinerary
      itinerary.forEach(day => {
        const existsInFull = fullItinerary.some(fullDay => fullDay.date === day.date);
        if (!existsInFull) {
          updatedFullItinerary.push(day);
        }
      });
      
      // Save the merged itinerary to preserve all data
      try {
        await updateDoc(tripRef, { itinerary: updatedFullItinerary });
        console.log(`[handleSectionChange] Successfully saved merged itinerary before section change: ${updatedFullItinerary.length} days`);
      } catch (error) {
        console.error(`[handleSectionChange] Error saving itinerary before section change:`, error);
      }
    }
    
    // Update active section
    setActiveSection(newActiveSection);
    
    // Force a reload of the itinerary data to ensure we have the latest
    console.log(`[handleSectionChange] Switching to section: ${newActiveSection || 'main'}`);
    
    // We'll let the useEffect hook triggered by activeSection change handle loading the new itinerary
  };

  return (
    <div className="trip-detail-container">
      {isMobile && onMenuClick && (
        <button className="menu-btn" onClick={onMenuClick}>
          <IoMenu size={24} />
        </button>
      )}

      {/* Section selector */}
      {sections && sections.length > 0 && (
        <div className="section-selector">
          <div 
            className={`section-tab ${!activeSection ? 'active' : ''}`}
            onClick={() => handleSectionChange(null)}
          >
            <div className="section-tab-location">{location.split(',')[0]} (Main)</div>
            <div className="section-tab-dates">
              {startDate && endDate ? 
                `${new Date(startDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'})} - ${new Date(endDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'})}` : ''}
            </div>
          </div>
          
          {sections.map((section, index) => (
            <div 
              key={index} 
              className={`section-tab ${activeSection === `section-${section.location}` ? 'active' : ''}`}
              onClick={() => handleSectionChange(`section-${section.location}`)}
            >
              <div className="section-tab-location">{section.location.split(',')[0]}</div>
              <div className="section-tab-dates">
                {section.startDate && section.endDate ? 
                  `${new Date(section.startDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'})} - ${new Date(section.endDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'})}` : ''}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="map-container" style={{ height: mapHeight }}>
        <TripMap
          center={activeCenter}
          places={places}
          selectedPlaceId={selectedPlaceId}
          onMarkerClick={onMarkerClick}
          handleMapClick={handleMapClick}
          getMarkerIcon={getMarkerIcon}
          mapRef={mapRef}
        />
      </div>

      <div className="resizer" onMouseDown={startResizing}></div>

      <div className="content-section-tabs">
        <button 
          className={`content-section-tab ${activeContentSections.includes('brainstorm') ? 'active' : ''}`}
          onClick={() => handleSwitchSection('brainstorm')}
        >
          Brainstorm
        </button>
        <button 
          className={`content-section-tab ${activeContentSections.includes('itinerary') ? 'active' : ''}`}
          onClick={() => handleSwitchSection('itinerary')}
        >
          Itinerary
        </button>
        <button 
          className={`content-section-tab ${activeContentSections.includes('details') ? 'active' : ''}`}
          onClick={() => handleSwitchSection('details')}
        >
          Details
        </button>
      </div>
      
      <div
        className={`places-container ${activeContentSections.join(' ')}`}
        style={{ height: placesContainerHeight }}
      >
        {activeContentSections.includes('brainstorm') && (
          <div className="brainstorm-section">
            <TripPlaces
              tripId={tripId}
              tripLocation={activeSectionData.activeLocation}
              bounds={bounds}
              autocompleteRef={autocompleteRef}
              inputValue={inputValue}
              setInputValue={setInputValue}
              onPlaceChanged={onPlaceChanged}
              places={places}
              selectedPlaceId={selectedPlaceId}
              onSelectPlace={onSelectPlace}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              handleCategoryClick={handleCategoryClick}
              categoryPlaces={categoryPlaces}
              handleDeletePlace={handleDeletePlace}
              toggleMustGo={toggleMustGo}
              isMustGo={isMustGo}
              notesPlaceId={notesPlaceId}
              toggleNotes={toggleNotes}
              tempNotes={tempNotes}
              setTempNotes={setTempNotes}
              saveNotes={saveNotes}
              isEditingNotes={isEditingNotes}
              startEditingNotes={startEditingNotes}
              hasNotes={hasNotes}
              onMenuClick={onMenuClick}
              isMobile={isMobile}
              isInItinerary={isInItinerary}
              itineraryDays={itinerary}
              onAddPlaceToItinerary={handleAddPlaceToItinerary}
              onInspirationClick={handleInspirationClick}
              onPlaceDoubleClick={handlePlaceDoubleClick}
              activeSection={activeSection}
            />
          </div>
        )}

        {activeContentSections.includes('itinerary') && (
          <div className="itinerary-section">
            <TripItinerary
              itinerary={itinerary}
              onItineraryChange={onItineraryChange}
              places={places}
            />
          </div>
        )}

        {activeContentSections.includes('details') && (
          <div className="details-section">
            <TripDetails
              tripId={tripId}
              trip={trip}
            />
          </div>
        )}
      </div>

      {showSuggestionModal && inspirationCategory && (
        <SuggestionModal
          category={inspirationCategory}
          tripLocation={location || 'Unknown Location'}
          sectionLocation={activeSectionData.activeLocation}
          sectionId={activeSection}
          existingPlaceNames={existingPlaceNamesForInspiration}
          suggestions={suggestionsData}
          generatedDate={generatedDate}
          loading={loadingSuggestions}
          error={suggestionError}
          onSelectSuggestion={handleSelectSuggestion}
          onClose={() => setShowSuggestionModal(false)}
        />
      )}
    </div>
  );
}

export default TripDetail;
