// src/components/TripDetail/SuggestionModal.js
import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy, limit, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import '../../styles/TripDetail/SuggestionModal.css';

function SuggestionModal({ 
  category, 
  tripLocation, 
  sectionLocation, // Add sectionLocation prop
  sectionId, // Add sectionId prop
  suggestions, 
  generatedDate,
  loading,
  error,
  onSelectSuggestion, 
  onClose 
}) {
  const [communityRecommendations, setCommunityRecommendations] = useState([]);
  const [loadingCommunity, setLoadingCommunity] = useState(false);
  
  // Fetch community recommendations when modal opens
  useEffect(() => {
    console.log('[SuggestionModal] Rendering modal for category:', category, 'Loading:', loading, 'Error:', error);
    console.log('[SuggestionModal] Section info:', sectionId, sectionLocation);
    
    const fetchCommunityRecommendations = async () => {
      try {
        setLoadingCommunity(true);
        
        // Use section location if available, otherwise use trip location
        const effectiveLocation = sectionLocation || tripLocation;
        
        // Get location document from locations collection
        const locationId = effectiveLocation.toLowerCase().replace(/\s+/g, '-');
        const locationRef = doc(db, "locations", locationId);
        const locationDoc = await getDoc(locationRef);
        
        let recommendations = [];
        
        if (locationDoc.exists()) {
          const locationData = locationDoc.data();
          
          // Get places for this category
          const categoryPlaces = locationData[category] || [];
          
          // Filter for community recommendations (user count > 0)
          recommendations = categoryPlaces
            .filter(place => (place.userCount || 0) > 0)
            .map(place => ({
              id: `${locationId}_${category}_${place.name.toLowerCase().replace(/\s+/g, '-')}`,
              name: place.name,
              description: place.description,
              category: place.category,
              userCount: place.userCount || 0,
              isAIGenerated: place.isAIGenerated || false
            }))
            // Sort by user count descending
            .sort((a, b) => b.userCount - a.userCount);
        }
        
        // Take top 5 recommendations
        const topRecommendations = recommendations.slice(0, 5);
        
        console.log(`[SuggestionModal] Found ${topRecommendations.length} community recommendations for ${category} in ${effectiveLocation}`);
        setCommunityRecommendations(topRecommendations);
        setLoadingCommunity(false);
      } catch (error) {
        console.error("[SuggestionModal] Error fetching community recommendations:", error);
        setLoadingCommunity(false);
      }
    };
    
    fetchCommunityRecommendations();
  }, [category, tripLocation, sectionLocation, sectionId]);

  // Create a set of names from community recommendations for filtering AI suggestions
  const communityPlaceNames = new Set(communityRecommendations.map(item => item.name));

  // Format the generated date
  const formatDate = (date) => {
    if (!date) return 'Unknown date';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="suggestion-modal-overlay" onClick={onClose}>
      <div className="suggestion-modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h3>{category} Suggestions for {(sectionLocation || tripLocation).split(',')[0]}</h3>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>
        <div className="modal-content">
          {(loading || loadingCommunity) && <p>Loading suggestions...</p>}
          {error && <p className="error">{error}</p>}
          
          {!loading && !loadingCommunity && !error && (
            <>
              {/* Community Recommendations Section */}
              {communityRecommendations.length > 0 && (
                <div className="recommendation-section">
                  <h4 className="recommendation-header">Recommended by Community</h4>
                  <ul className="suggestions-list">
                    {communityRecommendations.map((item, index) => (
                      <li 
                        key={`community-${index}`} 
                        className="suggestion-item community-item" 
                        onClick={() => onSelectSuggestion(item)}
                      >
                        <div className="community-badge">
                          <span>Put by {item.userCount} users in their itinerary</span>
                        </div>
                        {/* Image removed to save costs. Uncomment below to reinstate images
                        <img 
                          src={item.imageUrl || 'https://via.placeholder.com/80'} 
                          alt={item.name} 
                          className="suggestion-img" 
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://via.placeholder.com/80';
                          }}
                        /> */}
                        <div className="suggestion-info">
                          <strong>{item.name}</strong>
                          <p>{item.description || `Popular ${category.toLowerCase()} choice in ${tripLocation.split(',')[0]}`}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* AI Suggestions Section */}
              {suggestions && suggestions.length > 0 && (
                <div className="recommendation-section">
                  <h4 className="recommendation-header">
                    Suggested by AI
                  </h4>
                  <ul className="suggestions-list">
                    {suggestions
                      // Filter out items that are already in community recommendations
                      .filter(item => !communityPlaceNames.has(item.name))
                      .map((item, index) => (
                        <li 
                          key={`ai-${index}`} 
                          className="suggestion-item" 
                          onClick={() => onSelectSuggestion(item)}
                        >
                          {/* Image removed to save costs. Uncomment below to reinstate images
                          <img 
                            src={item.imageUrl || 'https://via.placeholder.com/80'} 
                            alt={item.name} 
                            className="suggestion-img" 
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = 'https://via.placeholder.com/80';
                            }}
                          /> */}
                          <div className="suggestion-info">
                            <strong>{item.name}</strong>
                            <p>{item.description}</p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              {/* No suggestions message */}
              {communityRecommendations.length === 0 && (!suggestions || suggestions.length === 0) && (
                <p>No suggestions available for {category} in {(sectionLocation || tripLocation).split(',')[0]}.</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SuggestionModal;
