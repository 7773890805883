import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { DateRange } from 'react-date-range';
import { format, addDays } from 'date-fns';
import { IoPeopleOutline, IoTrashOutline, IoAddOutline, IoEye, IoEyeOff } from 'react-icons/io5';
import { FaCircle } from 'react-icons/fa';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../styles/Modals.css';

function EditTripModal({ trip, onClose, onSave }) {
  const [numPeople, setNumPeople] = useState(trip.numPeople || 1);
  const [locationInput, setLocationInput] = useState(trip.location || '');
  
  // Parse dates for the date picker
  const startDateObj = trip.startDate ? new Date(trip.startDate) : new Date();
  const endDateObj = trip.endDate ? new Date(trip.endDate) : new Date();
  
  const [dateRange, setDateRange] = useState([
    {
      startDate: startDateObj,
      endDate: endDateObj,
      key: 'selection',
    },
  ]);
  
  const [showCalendar, setShowCalendar] = useState(false);
  const initialSections = (trip.sections || []).map(section => ({
    ...section,
    startDate: section.startDate ? new Date(section.startDate) : startDateObj,
    endDate: section.endDate ? new Date(section.endDate) : endDateObj
  }));
  
  const [sections, setSections] = useState(initialSections);
  const [sectionCalendarState, setSectionCalendarState] = useState({});
  const [sectionDates, setSectionDates] = useState([]);
  // New state: when true (default) show other sections in the calendar
  const [viewOtherSections, setViewOtherSections] = useState(true);
  
  // Section colors array
  const sectionColors = ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#8F44AD', '#00ACC1', '#FF7043'];
  
  // Ref for main trip date range
  const tripDateRangeRef = useRef({
    startDate: startDateObj,
    endDate: endDateObj
  });
  
  const autocompleteRef = useRef(null);
  const dateRangeRef = useRef(null);
  const sectionAutocompleteRefs = useRef([]);

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry && place.geometry.location) {
        autocompleteRef.current.lat = place.geometry.location.lat();
        autocompleteRef.current.lng = place.geometry.location.lng();
        setLocationInput(place.formatted_address || place.name);
      }
    }
  };
  
  const onSectionPlaceChanged = (index) => {
    if (sectionAutocompleteRefs.current[index] !== null) {
      const place = sectionAutocompleteRefs.current[index].getPlace();
      if (place && place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        sectionAutocompleteRefs.current[index].lat = lat;
        sectionAutocompleteRefs.current[index].lng = lng;
        
        const updatedSections = [...sections];
        updatedSections[index].location = place.formatted_address || place.name;
        setSections(updatedSections);
      }
    }
  };

  const addSection = () => {
    const lastSection = sections.length > 0 ? 
      [...sections].sort((a, b) => {
        const aEndDate = a.endDate || dateRange[0]?.endDate || addDays(new Date(), 7);
        const bEndDate = b.endDate || dateRange[0]?.endDate || addDays(new Date(), 7);
        return new Date(aEndDate) - new Date(bEndDate);
      }).pop() : 
      null;
    
    const defaultStartDate = lastSection && lastSection.endDate ? 
      lastSection.endDate : 
      tripDateRangeRef.current.startDate;

    const newSection = { 
      location: '', 
      lat: null, 
      lng: null, 
      startDate: defaultStartDate, 
      endDate: tripDateRangeRef.current.endDate 
    };
      
    const updatedSections = [...sections, newSection];
    setSections(updatedSections);
    
    // Update sectionDates for other sections
    const otherSections = updatedSections.filter((_, idx) => idx !== updatedSections.length - 1);
    const updatedSectionDates = otherSections.map((section, idx) => ({
      startDate: section.startDate || dateRange[0]?.startDate || new Date(),
      endDate: section.endDate || dateRange[0]?.endDate || addDays(new Date(), 7),
      key: `section-${updatedSections.indexOf(section)}`,
      color: sectionColors[updatedSections.indexOf(section) % sectionColors.length],
      showDateDisplay: false,
      autoFocus: false
    })).filter(date => date.startDate && date.endDate);
    
    setSectionDates(updatedSectionDates);
    
    sectionAutocompleteRefs.current = [...sectionAutocompleteRefs.current, null];
  };

  const removeSection = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
    
    const updatedRefs = [...sectionAutocompleteRefs.current];
    updatedRefs.splice(index, 1);
    sectionAutocompleteRefs.current = updatedRefs;
    
    const updatedSectionDates = updatedSections.map((section, idx) => ({
      startDate: section.startDate || dateRange[0]?.startDate || new Date(),
      endDate: section.endDate || dateRange[0]?.endDate || addDays(new Date(), 7),
      key: `section-${idx}`,
      color: sectionColors[idx % sectionColors.length],
      showDateDisplay: false,
      autoFocus: false
    })).filter(date => date.startDate && date.endDate);
    
    setSectionDates(updatedSectionDates);
    
    setSectionCalendarState(prev => {
      const updated = { ...prev };
      delete updated[index];
      const newState = {};
      Object.keys(updated).forEach(key => {
        const keyNum = parseInt(key, 10);
        if (keyNum > index) {
          newState[keyNum - 1] = updated[key];
        } else {
          newState[key] = updated[key];
        }
      });
      return newState;
    });
  };

  const updateSectionLocation = (index, value) => {
    const updatedSections = [...sections];
    updatedSections[index].location = value;
    setSections(updatedSections);
  };
  
  const updateSectionDate = (index, field, date) => {
    const updatedSections = [...sections];
    updatedSections[index][field] = date;
    setSections(updatedSections);
  };
  
  const handleDateChange = (ranges) => {
    const { selection } = ranges;
    setDateRange([selection]);
    tripDateRangeRef.current = {
      startDate: selection.startDate,
      endDate: selection.endDate
    };
  };
  
  // Toggle calendar on/off when date display is clicked
  useEffect(() => {
    if (!dateRangeRef.current) return;
    const dateDisplayEl = dateRangeRef.current.querySelector('.rdrDateDisplay');
    if (!dateDisplayEl) return;

    const handleClick = () => setShowCalendar(prev => !prev);
    dateDisplayEl.addEventListener('click', handleClick);
    return () => dateDisplayEl.removeEventListener('click', handleClick);
  }, []);
  
  // Initialize section dates when component mounts
  useEffect(() => {
    if (sections.length > 0) {
      setSectionDates([]);
    }
  }, []);
  
  // Update section dates when a section's calendar is open
  useEffect(() => {
    const openCalendarIndexKey = Object.keys(sectionCalendarState).find(
      key => sectionCalendarState[parseInt(key, 10)]
    );
    
    if (openCalendarIndexKey !== undefined) {
      const currentIndex = parseInt(openCalendarIndexKey, 10);
      const otherSectionDates = sections.reduce((acc, section, idx) => {
        if (idx !== currentIndex) {
          acc.push({
            startDate: section.startDate || dateRange[0]?.startDate || new Date(),
            endDate: section.endDate || dateRange[0]?.endDate || addDays(new Date(), 7),
            key: `section-${idx}`,
            color: sectionColors[idx % sectionColors.length],
            showDateDisplay: false,
            autoFocus: false
          });
        }
        return acc;
      }, []);
      setSectionDates(otherSectionDates);
    } else {
      setSectionDates([]);
    }
  }, [sectionCalendarState, sections, dateRange, sectionColors]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!locationInput) {
      alert('Location cannot be empty.');
      return;
    }
    
    const startDate = dateRange[0].startDate;
    const endDate = dateRange[0].endDate;

    if (endDate <= startDate) {
      alert('End date must be after start date.');
      return;
    }
    
    const validSections = sections.filter(section => section.location.trim() !== '');
    const sortedValidSections = [...validSections].sort((a, b) => 
      new Date(a.startDate || startDate) - new Date(b.startDate || startDate)
    );
    
    const sectionsWithCoords = sortedValidSections.map((section) => {
      const sectionStartDate = format(section.startDate || startDate, 'yyyy-MM-dd');
      const sectionEndDate = format(section.endDate || endDate, 'yyyy-MM-dd');
      const originalIndex = sections.findIndex(s => s === section);
      
      return {
        location: section.location,
        lat: sectionAutocompleteRefs.current[originalIndex]?.lat,
        lng: sectionAutocompleteRefs.current[originalIndex]?.lng,
        startDate: sectionStartDate,
        endDate: sectionEndDate
      };
    });
    
    const updated = {
      numPeople,
      location: locationInput,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      sections: sectionsWithCoords
    };
    
    if (autocompleteRef.current && autocompleteRef.current.lat !== undefined) {
      updated.lat = autocompleteRef.current.lat;
      updated.lng = autocompleteRef.current.lng;
    }
    
    await onSave(updated);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-title">
          <h3>Edit Trip</h3>
        </div>
        
        <form onSubmit={handleSave} className="modal-form">
          {/* Location + People row */}
          <div className="form-row">
            <div className="location-input-wrapper">
              <Autocomplete
                onLoad={autocomplete => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  type="text"
                  className="form-input location-input"
                  value={locationInput}
                  onChange={e => setLocationInput(e.target.value)}
                  placeholder="Enter city or country"
                />
              </Autocomplete>
            </div>

            <div className="people-wrapper">
              <IoPeopleOutline size={20} className="people-icon" />
              <input
                type="number"
                className="form-input people-input"
                min="1"
                value={numPeople}
                onChange={e => setNumPeople(parseInt(e.target.value, 10))}
              />
            </div>
          </div>

          {/* Date range row */}
          <div
            ref={dateRangeRef}
            className={`dateRange-container ${showCalendar ? 'expanded' : 'collapsed'}`}
          >
            <div className="date-labels">
              <span>Start</span>
              <span>End</span>
            </div>
            <DateRange
              editableDateInputs
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              rangeColors={['#1976d2']}
              showDateDisplay={true}
              showMonthAndYearPickers={true}
              showSelectionPreview={false}
              direction="horizontal"
              minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
              maxDate={new Date(new Date().getFullYear() + 10, 11, 31)}
            />
          </div>
          
          {/* Trip Sections */}
          <div className="sections-container">
            <div className="sections-header">
              <h4>Trip Sections</h4>
              <button
                type="button"
                className="view-toggle-btn"
                onClick={() => setViewOtherSections(prev => !prev)}
                title={viewOtherSections ? "Show only current section" : "Show all sections"}
              >
                {viewOtherSections ? <IoEye size={20} /> : <IoEyeOff size={20} />}
              </button>
            </div>
            
            {sections.map((section, index) => {
              const startDateString = section.startDate ? format(section.startDate, 'dd/MM/yy') : '';
              const endDateString = section.endDate ? format(section.endDate, 'dd/MM/yy') : '';
              const dateRangeText = startDateString && endDateString ? `${startDateString} - ${endDateString}` : 'Select dates';
              const showSectionCalendar = sectionCalendarState[index] || false;
              
              return (
                <div key={index} className="section-row compact">
                  <div className="section-content">
                    <div className="section-color-indicator">
                      <FaCircle 
                        size={12} 
                        color={sectionColors[index % sectionColors.length]} 
                      />
                    </div>
                    <div className="section-location-wrapper">
                      <Autocomplete
                        onLoad={autocomplete => {
                          sectionAutocompleteRefs.current[index] = autocomplete;
                          if (section.lat && section.lng) {
                            autocomplete.lat = section.lat;
                            autocomplete.lng = section.lng;
                          }
                        }}
                        onPlaceChanged={() => onSectionPlaceChanged(index)}
                      >
                        <input
                          type="text"
                          className="form-input section-location-input"
                          value={section.location}
                          onChange={e => updateSectionLocation(index, e.target.value)}
                          placeholder="Enter section location"
                        />
                      </Autocomplete>
                    </div>
                    
                    <div 
                      className="section-date-range-text"
                      onClick={() => {
                        setSectionCalendarState(prev => ({
                          ...prev,
                          [index]: !prev[index]
                        }));
                      }}
                    >
                      {dateRangeText}
                    </div>
                    
                    <button 
                      type="button" 
                      className="remove-section-btn"
                      onClick={() => removeSection(index)}
                      aria-label="Remove section"
                    >
                      <IoTrashOutline size={16} />
                    </button>
                  </div>
                  
                  {showSectionCalendar && (
                    <div className="section-date-picker">
                      <DateRange
                        editableDateInputs
                        onChange={(ranges) => {
                          if (!ranges || !ranges.selection) return;
                          const { selection } = ranges;
                          if (selection && selection.startDate && selection.endDate) {
                            updateSectionDate(index, 'startDate', selection.startDate);
                            updateSectionDate(index, 'endDate', selection.endDate);
                            if (selection.startDate.getTime() !== selection.endDate.getTime()) {
                              setSectionCalendarState(prev => ({
                                ...prev,
                                [index]: false
                              }));
                            }
                          }
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={[
                          {
                            startDate: section.startDate || dateRange[0]?.startDate || new Date(),
                            endDate: section.endDate || dateRange[0]?.endDate || addDays(new Date(), 7),
                            key: 'selection'
                          },
                          ...(viewOtherSections ? sectionDates : [])
                        ]}
                        rangeColors={[
                          sectionColors[index % sectionColors.length],
                          ...(viewOtherSections ? sectionDates.map(date => date.color) : [])
                        ]}
                        renderDayContent={(day) => {
                          const matchingSections = sectionDates.filter(range => 
                            day.date >= range.startDate && day.date <= range.endDate
                          );
                          
                          return matchingSections.length > 0 ? (
                            <div className="section-date-dot-container">
                              <div>{day.date.getDate()}</div>
                              <div className="section-date-dots">
                                {matchingSections.map((section, idx) => (
                                  <FaCircle 
                                    key={idx} 
                                    size={6} 
                                    color={section.color}
                                    style={{ margin: '0 1px' }}
                                  />
                                ))}
                              </div>
                            </div>
                          ) : null;
                        }}
                        showDateDisplay={false}
                        showMonthAndYearPickers={true}
                        showSelectionPreview={false}
                        direction="horizontal"
                        minDate={dateRange[0]?.startDate || new Date()}
                        maxDate={dateRange[0]?.endDate || addDays(new Date(), 7)}
                        className="section-calendar"
                      />
                    </div>
                  )}
                </div>
              );
            })}
            
            <button 
              type="button" 
              className="add-section-btn"
              onClick={addSection}
            >
              <IoAddOutline size={14} /> Add Section
            </button>
          </div>

          {/* Buttons row */}
          <div className="modal-actions">
            <button type="button" onClick={onClose} className="cancel-btn">
              Cancel
            </button>
            <button type="submit" className="submit-btn">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditTripModal;
